import React from "react";
import "./../styles/index.css";
import "./../styles/normalize.css";
import "./../styles/global.scss";
import "rsuite/dist/rsuite.min.css";

import IndexPage from "./index";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";

// import AboutPage from "./about";
// import DashboardPage from "./dashboard";

import BetaSignup from "./beta-signup";

import Projects from "./custom/Projects/Projects";
import Project from "./custom/Project/Project";

import "./../util/analytics";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";

import { Provider } from "react-redux";
import store from "../state/reduxStore";
import UserSettings from "./custom/Settings/UserSettings";

function App(props) {
  return (
    <Provider store={store}>
      <QueryClientProvider>
        <AuthProvider>
          <Router>
            <>
              <Switch>
                {/* App routes without footer and header */}

                <Route exact path="/app/projects" component={Projects} />

                <Route
                  exact
                  path="/app/settings/:section"
                  component={UserSettings}
                />

                <Route
                  exact
                  path="/app/project/:id/:name/:content_type?/:digests?"
                  component={Project}
                />

                {/* Boilerplate routes with standard footer and header */}

                <Route exact path="/" component={IndexPage} />

                <Route exact path="/beta-signup" component={BetaSignup} />

                {/* <Route exact path="/about" component={AboutPage} /> */}

                <Route exact path="/faq" component={FaqPage} />

                <Route exact path="/contact" component={ContactPage} />

                <Route exact path="/pricing" component={PricingPage} />

                {/* <Route exact path="/dashboard" component={DashboardPage} /> */}

                <Route exact path="/auth/:type" component={AuthPage} />

                <Route
                  exact
                  path="/settings/:section"
                  component={SettingsPage}
                />

                <Route exact path="/legal/:section" component={LegalPage} />

                <Route exact path="/purchase/:plan" component={PurchasePage} />

                <Route
                  exact
                  path="/firebase-action"
                  component={FirebaseActionPage}
                />

                <Route component={NotFoundPage} />
              </Switch>
            </>
          </Router>
        </AuthProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
