import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { requireAuth } from "../../../util/auth";
import { useAuth } from "../../../util/auth";

// import { Link, useRouter } from "../../../util/router.js";
// import Spinner from "../../../components/appComponents/Spinner/Spinner";

import Meta from "../../../components/Meta";

import ProjectsList from "./ProjectsList";
import ProjectsHeader from "./ProjectsHeader";
import NewProjectModal from "./NewProjectModal";

import Layout from "../../../components/appComponents/Layout/Layout";

import { fetchProjects } from "../../../reducers/projectsSlice";
import { addProject } from "../../../reducers/projectsSlice";

import { reduxState } from "../../../interfaces/reduxState";
import { projectType } from "../../../interfaces/types";

import styles from "./Projects.module.scss";

interface ContentType {
  label: string;
  value: projectType;
  description: string;
}

function Projects(): JSX.Element {
  const auth = useAuth();
  const dispatch: any = useDispatch();
  const projects = useSelector((state: reduxState) => state.projects);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCreate = (selectedContentType: ContentType | "") => {
    if (!selectedContentType) return;

    dispatch(
      addProject({ projectType: selectedContentType, userId: auth.user.id })
    );

    handleClose();
  };

  useEffect(() => {
    // here to only trigger once on mount
    //TODO: fetch only the projects that belong to the user
    //TODO: enable rule that a user can only fetch an individual project when the ID matches.

    setIsLoading(true);
    dispatch(fetchProjects(auth.user.id)).then(() => {
      setIsLoading(false);
    });
  }, [dispatch, auth.user.id]);

  return (
    <Layout sideMenuOpen={true}>
      <Meta title="Projects" />
      <div className={styles.projectsView}>
        <ProjectsHeader handleOpen={handleOpen} headline="My Projects" />
        <div className={styles.body}>
          {/* <React.Suspense fallback={<div>Loading...</div>}> */}
          {/* <Spinner /> */}
          <ProjectsList projects={projects} isLoading={isLoading} />
          {/* </React.Suspense> */}

          {/* <ProjectCard /> */}
          <NewProjectModal
            open={open}
            handleClose={handleClose}
            handleCreate={handleCreate}
          />
        </div>
      </div>
    </Layout>
  );
}

export default requireAuth(Projects);
