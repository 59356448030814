// Map our custom plan IDs ("basic", "premium", etc) to Stripe price IDs
const stripePriceIds = {
  starter: process.env.REACT_APP_STRIPE_PRICE_STARTER,
  pro: process.env.REACT_APP_STRIPE_PRICE_PRO,
  business: process.env.REACT_APP_STRIPE_PRICE_BUSINESS,
};

// Map stripe price ID to granted maximum amount of content. (word count)
export const maxWordCounts = {
  [process.env.REACT_APP_STRIPE_PRICE_STARTER.toString()]: 10000,
  [process.env.REACT_APP_STRIPE_PRICE_PRO.toString()]: 20000,
  [process.env.REACT_APP_STRIPE_PRICE_BUSINESS.toString()]: 50000,
};

// Used during open Beta
export const maxWordCount = 1000;

// Get Stripe priceId
export function getStripePriceId(planId) {
  return stripePriceIds[planId];
}

// Get friendly plan ID ("basic", "premium", etc) by Stripe plan ID
// Used in auth.js to include planId in the user object
export function getFriendlyPlanId(stripePriceId) {
  return Object.keys(stripePriceIds).find(
    (key) => stripePriceIds[key] === stripePriceId
  );
}
