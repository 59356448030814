import { Node } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";

// import store from "../../reduxStore";

import uniqid from "uniqid";
import { ReactNodeViewRenderer } from "@tiptap/react";

const SceneNode = Node.create({
  name: "div",
  draggable: true,
  content: "(paragraph|list?)*",
  group: "block",

  addGlobalAttributes() {
    return [
      {
        types: ["div"],
        attributes: {
          blockId: {
            default: null,
            rendered: true,
            keepOnSplit: false,
          },
          name: {
            default: null,
            rendered: true,
            keepOnSplit: false,
          },
        },
      },
    ];
  },

  addProseMirrorPlugins() {
    // let enterCount = 0;
    return [
      new Plugin({
        key: new PluginKey("scene-plugin"),
        props: {
          handleKeyDown: (view, event) => {
            // event.which === 46
            if (event.key === "Backspace") {
              view.state.deleting = true;
            }

            if (event.key === "Enter") {
              //
            }

            return false;
          },
        },

        filterTransaction: (transaction, state) => {
          var isDeleting = state.deleting;

          if (!isDeleting) {
            return true;
          }

          // Only triggers on backspace delete of last character:
          var result = true;

          transaction.mapping.maps.forEach((map) => {
            map.forEach((oldStart, oldEnd, newStart, newEnd) => {
              state.doc.nodesBetween(
                oldStart,
                oldEnd,
                (node, number, pos, parent, index) => {
                  if (node.type.name === "div") {
                    // Duplicate as before every not isDeleting Transaction is approved.

                    if (isDeleting) {
                      // 0 = last char, 2 = multiple chars selected
                      if (newStart === 0 || newStart === 2) {
                        result = false;
                        state.deleting = false;
                      }
                    }
                  }
                }
              );
            });
          });

          return result;
        },

        appendTransaction: (_transactions, oldState, newState) => {
          // always add function back to state scenes.

          if (newState.doc === oldState.doc) {
            return;
          }

          // const state = store.getState();

          // const {
          //   content: { content: newScenes },
          // } = newState.doc;
          // const {
          //   content: { content: oldScenes },
          // } = oldState.doc;

          const transaction = newState.tr;

          newState.doc.descendants((node, pos, parent) => {
            const { type, attrs } = node;

            // Only process scene nodes.
            if (type.name !== "div") {
              return;
            }

            // Identify if the node is new are already exists, based on the blockId's existance.
            if (attrs.blockId === null) {
              console.log("NEW SCENE", node.attrs);
              const id = uniqid();
              const newNode = {
                ...node.attrs,
                blockId: id,
                pos: pos,
                // name: "BLOB",
              };

              transaction.setNodeMarkup(pos, undefined, newNode);
            } else {
              transaction.setNodeMarkup(pos, undefined, {
                ...node.attrs,
                pos: pos,
              });
            }
          });

          return transaction;
        },
      }),
    ];
  },

  parseHTML() {
    return [{ tag: "div" }];
  },
  renderHTML({ HTMLAttributes }) {
    return ["div", { ...HTMLAttributes, class: "scene" }, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer((props) => {
      // const scenes = useSelector((state) => state.project.scenes);

      return <div>Legacy</div>;
    });
  },

  onCreate() {
    // The editor is ready.
    // console.log("onCreate", this);
  },
  onUpdate() {
    // The content has changed.
    // console.log("onUpdate", this);
  },
  onSelectionUpdate({ editor }) {
    // The selection has changed.
    // console.log("onSelectionUpdate", this);
  },
  onTransaction({ transaction }) {
    // The editor state has changed.
    // console.log("onTransaction", this);
  },
  onFocus({ event }) {
    // The editor is focused.
    // console.log("onFocus", this);
  },
  onBlur({ event }) {
    // The editor isn’t focused anymore.
    // console.log("onBlur", this);
  },
  onDestroy() {
    // The editor is being destroyed.
    // console.log("onDestroy", this);
  },
});

export default SceneNode;
