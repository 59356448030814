import { ScriptContent } from "../interfaces/Script";

const defaultContent: ScriptContent = {
  type: "div",
  content: [
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: " ",
        },
      ],
    },
  ],
};

export default defaultContent;
