// Autor: Miguel Angel Reina
// Fecha: 11/10/2021

import React, { useState } from "react";

import { Input } from "rsuite";

// import styles from "./nameEditInput.module.scss";

const EditableName = ({ Title, name, size, handleChange }) => {
  const [editName, setEditName] = useState(false);

  return (
    <>
      {!editName ? (
        <Title
          onClick={(e) => {
            e.stopPropagation();
            setEditName((toggle) => !toggle);
          }}
        />
      ) : (
        <NameEditInput
          setEditName={setEditName}
          name={name}
          size={size}
          onChange={(value) => handleChange(value)}
        />
      )}
    </>
  );
};

export default EditableName;

const NameEditInput = ({ setEditName, name, onChange, size }) => {
  //   const dispatch = useDispatch();
  //   const navigate = useNavigate();
  //   let { id, content_type, digests } = useParams();

  return (
    <Input
      value={name}
      autoFocus={true}
      //   className={styles.headlineInput}
      width={300}
      onKeyDown={(e) => (e.key === "Enter" ? e.target.blur() : null)}
      onChange={onChange}
      onBlur={(e) => {
        if (e.target.value === "") {
          onChange("Untitled");
        }

        setEditName((toggle) => !toggle);
      }}
      size={size || "md"}
    />
  );
};
