import { AllowedScriptFilters } from "../interfaces/Filters";
import { ScriptProgress } from "../interfaces/Progress";

export const turnocat = (str: string, maxLength: number) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  } else {
    return str;
  }
};

export function debounce(fn: () => void, delay: number) {
  var timeout: NodeJS.Timeout;

  return function (this: any) {
    var context = this;
    var args: any = arguments;

    clearTimeout(timeout);
    timeout = setTimeout(function () {
      fn.apply(context, args);
    }, delay || 250);
  };
}

export function formatTime(minutes: number) {
  if (minutes < 1) {
    const seconds = minutes * 60;
    return Math.round(seconds) + " sec";
  } else {
    const roundedMinutes = minutes.toFixed(1);
    return roundedMinutes + " min" + (roundedMinutes === "1" ? "" : "s");
  }
}

export function calcTime(charCount: number) {
  return {
    read: () => formatTime(charCount / 200),
    spoken: () => formatTime(charCount / 130),
  };
}

export function checkFilterStatus(
  progress: ScriptProgress,
  filterState: Array<AllowedScriptFilters>
) {
  return progress
    ? Object.entries(progress).reduce((acc, [key, value]) => {
        if (filterState.includes(key as AllowedScriptFilters)) {
          if (value) {
            return acc && false;
          }
          return acc;
        }
        return acc;
      }, true)
    : true;
}
