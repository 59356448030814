import {
  // Whisper,
  // Button,
  // ButtonGroup,
  // Dropdown,
  // Popover,
  IconButton,
} from "rsuite";

import WithToolTip from "../WithToolTip/WithToolTip";
import PlusIcon from "@rsuite/icons/Plus";
// import ArrowDownIcon from "@rsuite/icons/ArrowDown";

const CreateButton = ({ onItemCreate, handleOpen }) => {
  return (
    <WithToolTip tooltip="Insert New Scene" trigger="hover">
      <IconButton
        circle
        onClick={() => onItemCreate()}
        appearance="primary"
        icon={<PlusIcon />}
      />
    </WithToolTip>
    // <ButtonGroup>
    //   <WithToolTip tooltip="Create blank scene" trigger="hover">
    //     <Button onClick={() => onItemCreate()} appearance="primary">
    //       New
    //     </Button>
    //   </WithToolTip>
    //   <Whisper
    //     trigger="click"
    //     speaker={({ className, onClose }, ref) => {
    //       const handleSelect = (eventKey) => {
    //         onClose();
    //         handleOpen();
    //       };
    //       return (
    //         <Popover ref={ref} className={className} full>
    //           <Dropdown.Menu onSelect={handleSelect}>
    //             <Dropdown.Item eventKey={1}> Insert Text Snippet</Dropdown.Item>
    //           </Dropdown.Menu>
    //         </Popover>
    //       );
    //     }}
    //   >
    //     <IconButton appearance="primary" icon={<ArrowDownIcon />} />
    //   </Whisper>
    // </ButtonGroup>
  );
};

export default CreateButton;
