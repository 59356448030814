import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import Image from "react-bootstrap/Image";
import Section from "./Section";
// import SectionHeader from "./SectionHeader";
import "./HeroSection.scss";
import "./SectionHeader.scss";

function SectionHeader(props) {
  // Render nothing if no title or subtitle
  if (!props.title && !props.subtitle) {
    return null;
  }

  return (
    <header
      className={
        "SectionHeader" + (props.className ? ` ${props.className}` : "")
      }
    >
      {props.title && (
        <h1
          className={
            "font-weight-bold" +
            (props.subtitle && props.spaced ? " mb-4" : "") +
            (!props.subtitle ? " mb-0" : "") +
            (props.size ? ` h${props.size}` : "")
          }
        >
          {/* {props.title} */}
          <div>
            Plan, write and manage your{" "}
            <span style={{ color: "#A435EF" }}>#udemy</span> course content in
            one place.
          </div>
        </h1>
      )}

      {props.subtitle && (
        <p className="SectionHeader__subtitle mb-0">{props.subtitle}</p>
      )}
    </header>
  );
}

function HeroSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Row className="align-items-center">
          <Col lg={12} className="text-center mb-5">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={1}
              spaced={true}
            />
            <LinkContainer to={props.buttonPath}>
              <Button variant={props.buttonColor} size="lg">
                {props.buttonText}
              </Button>
            </LinkContainer>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5 mt-lg-0 ">
            <figure className="mx-auto">
              <Image
                src={props.image}
                fluid={true}
                className="HeroSection__image"
              />
            </figure>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default HeroSection;
