import { Stack, IconButton } from "rsuite";

import { SimpleProgress } from "../Progress/Progress";

import AngleLeftIcon from "@rsuite/icons/legacy/AngleLeft";

import styles from "./lecturePanel.module.scss";

interface PanelHeaderProps {
  toggleLayout: () => void;
  progress: number;
}

const LecturePanelHeader = ({ toggleLayout, progress }: PanelHeaderProps) => {
  return (
    <header className={styles.header}>
      <Stack justifyContent="space-between">
        <Stack justifyContent="flex-start" alignItems="center">
          <IconButton
            onClick={() => {
              toggleLayout();
            }}
            appearance="subtle"
            icon={<AngleLeftIcon />}
          />
          <span>Curriculum</span>
        </Stack>
        <SimpleProgress
          percent={progress}
          width={"22px"}
          style={{ marginLeft: "15px" }}
        />
      </Stack>
    </header>
  );
};

export default LecturePanelHeader;
