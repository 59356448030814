import { useEffect, useState } from "react";
import { Modal, Button, Placeholder } from "rsuite";

import { useSelector, useDispatch } from "react-redux";

import { fetchAllSnippets } from "../../../reducers/snippetSlice";

import { useEditor } from "@tiptap/react";
import { EditorContent } from "@tiptap/react";

import { previewExtensions } from "../../../tipTap/extensions";

const Placeholders = ({ loading }) => {
  return loading ? null : (
    <>
      <Placeholder.Graph
        active={loading}
        height={150}
        style={{ marginTop: "10px" }}
      />
      <Placeholder.Graph
        active={loading}
        height={150}
        style={{ marginTop: "10px" }}
      />
      <Placeholder.Graph
        active={loading}
        height={150}
        style={{ marginTop: "10px" }}
      />
    </>
  );
};

const SnippetModal = ({ open, handleClose, handleInsert, key }) => {
  const [selectedSnippet, setSelectedSnippet] = useState(null);
  const snippets = useSelector(({ snippets }) => snippets);
  const dispatch = useDispatch();

  // Load all Snippets from DB

  // A snippet, needs a card with
  // - title
  // - text preview. (cut when too long)
  // - attached assets.

  useEffect(() => {
    dispatch(fetchAllSnippets());
  }, [dispatch]);

  // useEffect(() => {
  //   console.log("SNIPPS", snippets);
  // }, [snippets]);

  const SnippetPanel = ({ scene, script }) => {
    const editor = useEditor({
      extensions: previewExtensions,
      editable: false,
      content: {
        type: "doc",
        content: [script],
      },
    });

    return (
      <div
        key={key}
        onClick={() => {
          setSelectedSnippet(() => {
            //toggle
            if (
              selectedSnippet &&
              selectedSnippet.scene.blockId === scene.blockId
            ) {
              return null;
            }

            return { scene, script };
          });
        }}
        style={{
          cursor: "pointer",
          borderRadius: "5px",
          boxShadow:
            selectedSnippet && selectedSnippet.scene.blockId === scene.blockId
              ? "0px 0px 0px 2px rgba(19,101,255,0.84)"
              : "none",
        }}
      >
        <EditorContent editor={editor} />
      </div>
    );
  };

  return (
    <Modal overflow={true} open={open} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>Content Snippets</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "10px" }}>
        <Placeholders loading={Object.keys(snippets).length > 0} />

        {/* TODO: Replace Panel with a single editor, handle select of individual nodes within the editor.*/}
        {Object.values(snippets).map(({ scene, script }) => (
          <SnippetPanel scene={scene} script={script} key={scene.blockId} />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            handleInsert(selectedSnippet);
            handleClose();
          }}
          appearance="primary"
        >
          Insert
        </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SnippetModal;
