import React from "react";
import { Container } from "react-bootstrap";
import NavbarCustom from "./NavbarCustom";
import Footer from "./../components/Footer";

interface PageLayoutProps {
  children: React.ReactNode;
}

function PageLayout({ children }: PageLayoutProps) {
  return (
    <div>
      <NavbarCustom bg="white" variant="light" expand="md" />
      <Container className="mt-5" style={{ fontSize: "17px" }}>
        {children}
      </Container>
      <Footer
        bg="light"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        copyright={`© ${new Date().getFullYear()} CourseCrafts`}
        logo="https://uploads.divjoy.com/logo.svg"
      />
    </div>
  );
}

export default PageLayout;
