const Logo = (props) => (
  <svg
    viewBox="0 0 121 143"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M60.345 136.204C52.2581 136.204 44.4128 134.966 37.0231 132.508C29.8564 130.134 23.4148 126.727 17.8748 122.38C12.2977 118.004 7.91028 112.902 4.83355 107.205C1.62667 101.256 0 94.9352 0 88.4093C0 76.3814 5.64224 64.9029 15.8856 56.0775C16.8152 55.2675 18.228 55.3793 19.0274 56.3102C19.8361 57.2412 19.7246 58.6562 18.7951 59.4568C9.55555 67.4257 4.46173 77.7034 4.46173 88.4093C4.46173 112.297 29.5311 131.726 60.345 131.726C91.1588 131.726 116.228 112.288 116.228 88.4093C116.228 77.7127 111.134 67.4257 101.895 59.4568C100.956 58.6469 100.854 57.2412 101.662 56.3102C102.471 55.3793 103.875 55.2675 104.804 56.0775C115.048 64.9029 120.69 76.3907 120.69 88.4093C120.69 94.9352 119.063 101.256 115.856 107.205C112.78 112.902 108.392 118.013 102.815 122.38C97.2751 126.727 90.8335 130.125 83.6668 132.508C76.2771 134.957 68.4319 136.204 60.345 136.204Z"
      fill="black"
    />
    <path
      d="M105.826 60.0061H95.5551C94.3188 60.0061 93.3242 59.0006 93.3242 57.7718C93.3242 56.5429 94.3281 55.5375 95.5551 55.5375H105.826C106.784 55.5375 107.695 55.1651 108.373 54.4855C109.052 53.8059 109.424 52.9029 109.424 51.9347C109.424 49.9518 107.806 48.332 105.826 48.332H14.8627C13.9053 48.332 13.0037 48.7044 12.3158 49.384C11.628 50.0635 11.2654 50.9666 11.2654 51.9347C11.2654 53.9177 12.8828 55.5375 14.8627 55.5375H50.6124C51.8486 55.5375 52.8432 56.5429 52.8432 57.7718C52.8432 59.0006 51.8393 60.0061 50.6124 60.0061H14.8627C10.4196 60.0061 6.80371 56.3847 6.80371 51.9347C6.80371 49.7749 7.64029 47.7548 9.16471 46.228C10.6891 44.7013 12.7062 43.8634 14.8627 43.8634H105.836C110.279 43.8634 113.895 47.4848 113.895 51.9347C113.895 54.0945 113.058 56.1147 111.534 57.6414C110.009 59.1682 107.983 60.0061 105.826 60.0061Z"
      fill="black"
    />
    <path
      d="M29.9781 143H13.8322C13.0607 143 12.3449 142.599 11.936 141.938C11.527 141.278 11.4898 140.458 11.843 139.769L20.6642 122.091C21.2126 120.983 22.5512 120.536 23.6573 121.095C24.7634 121.644 25.2096 122.984 24.6519 124.092L17.4388 138.531H28.6024L33.5475 128.626C34.0959 127.518 35.4344 127.071 36.5406 127.63C37.6467 128.179 38.0929 129.52 37.5351 130.627L31.9766 141.771C31.5955 142.525 30.824 143 29.9781 143Z"
      fill="black"
    />
    <path
      d="M106.859 143H90.7135C89.8676 143 89.0961 142.525 88.715 141.762L83.1565 130.618C82.608 129.511 83.0542 128.17 84.151 127.621C85.2572 127.071 86.5957 127.518 87.1441 128.617L92.0892 138.522H103.253L96.049 124.074C95.5006 122.966 95.9468 121.625 97.0436 121.076C98.1497 120.527 99.4883 120.974 100.037 122.072L108.858 139.751C109.202 140.44 109.165 141.268 108.756 141.92C108.347 142.572 107.631 143 106.859 143Z"
      fill="black"
    />
    <path
      d="M27.6438 48.332C26.7421 48.332 25.8963 47.7827 25.5523 46.889L12.1392 11.3455C11.7767 10.3866 11.5908 9.39048 11.5908 8.37574C11.5908 4.11201 14.7791 0.53717 19.0177 0.0530771C22.8288 -0.375159 26.4354 1.82188 27.7832 5.41533L42.8416 45.2971C43.2784 46.4515 42.6928 47.7455 41.5402 48.1737C40.3876 48.602 39.0956 48.0248 38.668 46.8704L23.6096 6.98863C22.9775 5.31293 21.2951 4.27958 19.5197 4.48438C17.5398 4.70781 16.0526 6.38352 16.0526 8.36643C16.0526 8.83191 16.1362 9.29738 16.3128 9.75354L29.7259 45.2971C30.1628 46.4515 29.5772 47.7455 28.4246 48.1737C28.1736 48.2854 27.904 48.332 27.6438 48.332Z"
      fill="black"
    />
    <path
      d="M95.0993 46.5261H51.9365V77.3566L53.0576 80.7199L58.1026 82.9621L61.466 68.3877H69.8743V84.6438V94.1732L73.2376 98.6577L79.4037 95.2943V77.3566L85.0093 75.1144L89.4937 87.4466L95.0993 86.3255V46.5261Z"
      fill="#663399"
    />
    <path
      d="M73.5256 101.312C69.7703 101.312 66.7121 98.2494 66.7121 94.4883V71.4846C66.7121 70.2185 65.6804 69.1852 64.4162 69.1852C63.152 69.1852 62.1203 70.2185 62.1203 71.4846V78.0851C62.1203 81.8833 59.0342 84.9647 55.2511 84.9647C51.4679 84.9647 48.3818 81.874 48.3818 78.0851V46.0977C48.3818 44.8595 49.3857 43.8634 50.6127 43.8634C51.8397 43.8634 52.8436 44.8689 52.8436 46.0977V78.0757C52.8436 79.407 53.9218 80.4869 55.2511 80.4869C56.5803 80.4869 57.6585 79.407 57.6585 78.0757V71.4753C57.6585 67.7422 60.6888 64.7073 64.4162 64.7073C68.1436 64.7073 71.1739 67.7422 71.1739 71.4753V94.479C71.1739 95.7731 72.2242 96.8343 73.5256 96.8343C74.8269 96.8343 75.8773 95.7824 75.8773 94.479V79.0905C75.8773 75.5249 78.7681 72.6297 82.3282 72.6297C85.8883 72.6297 88.7791 75.5249 88.7791 79.0905V83.2146C88.7791 84.4713 89.8016 85.4861 91.0472 85.4861C92.2927 85.4861 93.3152 84.462 93.3152 83.2146V46.0977C93.3152 44.8595 94.3191 43.8634 95.5461 43.8634C96.7731 43.8634 97.777 44.8689 97.777 46.0977V83.2239C97.777 86.9384 94.756 89.9639 91.0472 89.9639C87.3384 89.9639 84.3174 86.9384 84.3174 83.2239V79.0998C84.3174 78.0013 83.425 77.1076 82.3282 77.1076C81.2314 77.1076 80.339 78.0013 80.339 79.0998V94.4883C80.339 98.2494 77.2809 101.312 73.5256 101.312Z"
      fill="black"
    />
    <circle cx="86.1309" cy="30.8305" r="3.92389" fill="#754F44" />
    <path
      d="M85.926 37.4585C82.0871 37.4585 78.9639 34.3305 78.9639 30.4857C78.9639 26.6409 82.0871 23.5129 85.926 23.5129C89.765 23.5129 92.8882 26.6409 92.8882 30.4857C92.8882 34.3305 89.765 37.4585 85.926 37.4585ZM85.926 27.9815C84.5503 27.9815 83.4256 29.1079 83.4256 30.4857C83.4256 31.8635 84.5503 32.99 85.926 32.99C87.3017 32.99 88.4265 31.8635 88.4265 30.4857C88.4265 29.1079 87.3017 27.9815 85.926 27.9815Z"
      fill="black"
    />
    <circle cx="58.1026" cy="26.3461" r="3.92389" fill="#1B998B" />
    <path
      d="M58.2356 33.7161C54.3966 33.7161 51.2734 30.5882 51.2734 26.7433C51.2734 22.8985 54.3966 19.7705 58.2356 19.7705C62.0745 19.7705 65.1978 22.8985 65.1978 26.7433C65.1978 30.5882 62.0745 33.7161 58.2356 33.7161ZM58.2356 24.2391C56.8599 24.2391 55.7352 25.3655 55.7352 26.7433C55.7352 28.1211 56.8599 29.2476 58.2356 29.2476C59.6113 29.2476 60.736 28.1211 60.736 26.7433C60.736 25.3655 59.6113 24.2391 58.2356 24.2391Z"
      fill="black"
    />
    <circle cx="73.7979" cy="10.6505" r="3.92389" fill="#EC7357" />
    <path
      d="M74.0374 17.499C70.1984 17.499 67.0752 14.3711 67.0752 10.5262C67.0752 6.68143 70.1984 3.55345 74.0374 3.55345C77.8763 3.55345 80.9995 6.68143 80.9995 10.5262C80.9995 14.3711 77.8763 17.499 74.0374 17.499ZM74.0374 8.022C72.6617 8.022 71.5369 9.14844 71.5369 10.5262C71.5369 11.904 72.6617 13.0305 74.0374 13.0305C75.4131 13.0305 76.5378 11.904 76.5378 10.5262C76.5378 9.13913 75.4131 8.022 74.0374 8.022Z"
      fill="black"
    />
  </svg>
);

export default Logo;
