import React from "react";

import { NodeViewWrapper, NodeViewContent } from "@tiptap/react";

// import { RxDragHandleDots2 } from "react-icons/rx";

import ItemHeader from "./ItemHeader";

import styles from "./snippetItem.module.scss";

export default function SnippetItem({ block, contentType }) {
  return (
    <NodeViewWrapper
      className={styles.draggable}
      style={{
        position: "inherit",
      }}
    >
      <ItemHeader
        name={block.name}
        type={block.type}
        contentType={contentType}
      />
      <div className={styles.itemBody}>
        <div
          className={styles.draggableHanlde}
          // contentEditable="false"
          draggable="false"
          data-drag-handle
        >
          {/* <RxDragHandleDots2 style={{ width: "25px", height: "25px" }} /> */}
        </div>
        <NodeViewContent className={styles.content} />
      </div>

      {/* <ItemFooter sceneId={props.node.attrs.blockId} /> */}
    </NodeViewWrapper>
  );
}
