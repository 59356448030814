import { IconButton, Divider } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";

import { DigestState } from "../../../interfaces/reduxState";
import { reduxState } from "../../../interfaces/reduxState";

import PlusIcon from "@rsuite/icons/Plus";

import {
  addLecture,
  addSection,
  // updateSections,
  deleteLecture,
  deleteSection,
  updateSectionTitle,
  updateSectionPosition,
  updateLecturePosition,
  updateLectureTitle,
} from "../../../reducers/courseStateSlice";

import {
  selectLecture,
  toggleDraggingSection,
  toggleLoadingProject,
} from "../../../reducers/uiSlice";
import {
  fetchLectureById,
  resetActiveDiggest,
} from "../../../reducers/projectStateSlice";

import WithToolTip from "../WithToolTip/WithToolTip";
import LectureList from "../LectureList/LectureList";

import {
  createNewSection,
  createNewLecture,
} from "../../../DataTypes/dataTypes";

import LecturePanelHeader from "./LecturePanelHeader";
import LecturePanelFooter from "./LecturePanelFooter";

import styles from "./lecturePanel.module.scss";

interface LecturePanelProps {
  clearEditorContent: () => void;
  handleReplaceContent: (script: any) => void;
  panelWidth: number;
  toggleLayout: () => void;
}

const LecturePanel = ({
  panelWidth,
  toggleLayout,
  clearEditorContent,
  handleReplaceContent,
}: LecturePanelProps) => {
  const dispatch = useDispatch();
  const { sections, progress } = useSelector(
    ({ courseState }: reduxState) => courseState.course
  );
  const { selectedLecture, loadingProject } = useSelector(
    ({ uiState }: reduxState) => uiState
  );

  function handleItemDelete(
    sectionIndex: number,
    lectureIndex: number,
    lectureId: string
  ) {
    dispatch(deleteLecture({ sectionIndex, lectureIndex, lectureId }));

    if (selectedLecture.id === lectureId) {
      console.log("reset Diggest", selectedLecture.id, lectureId);
      clearEditorContent();
      dispatch(resetActiveDiggest());
    }
  }

  function handleSectionDelete(sectionIndex: number) {
    dispatch(
      deleteSection({ sectionIndex, sectionId: sections[sectionIndex].id })
    );

    if (selectedLecture.sectionIndex === sectionIndex) {
      dispatch(resetActiveDiggest());
      clearEditorContent();
    }
  }

  function handleSectionAdd() {
    const newSection /*:Section*/ = createNewSection();
    dispatch(addSection({ newSection }));
  }

  function handleItemAdd(sectionIndex: number, itemIndex?: number) {
    // TBD: indexIndex can be used to add new item in the middle of the list
    const newLecture = createNewLecture();
    dispatch(addLecture({ sectionIndex, newLecture }));
  }

  function handleItemNameChange(
    sectionIndex: number,
    lectureIndex: number,
    lectureId: string,
    newName: string
  ) {
    dispatch(
      updateLectureTitle({ sectionIndex, lectureIndex, lectureId, newName })
    );
  }

  function handleSectionNameChange(sectionIndex: number, newName: string) {
    dispatch(updateSectionTitle({ sectionIndex, newName }));
  }

  type Source = {
    droppableId: string;
    index: number;
  };
  type Destination = {
    droppableId: string;
    index: number;
  };

  function handleDropLecture(source: Source, destination: Destination) {
    dispatch(updateLecturePosition({ source, destination }));
    const isSelected = (
      sourceSectionIndex: number,
      sourceItemIndex: number
    ) => {
      return (
        sourceSectionIndex === selectedLecture.sectionIndex &&
        sourceItemIndex === selectedLecture.itemIndex
      );
    };
    const droppableId = Number(source.droppableId);
    if (isSelected(droppableId, source.index)) {
      dispatch(
        selectLecture({
          sectionIndex: destination.droppableId,
          itemId: selectedLecture.id,
          itemIndex: destination.index,
        })
      );
    }
  }

  function handleDropSection(source: Source, destination: Destination) {
    dispatch(updateSectionPosition({ source, destination }));
  }

  interface DragEnd {
    source: Source;
    destination: Destination;
    type: "droppableSection" | "droppableLecture";
  }
  function handleDragEnd({ source, destination, type }: DragEnd) {
    console.log("handleDragEnd", source, destination, type);

    dispatch(toggleDraggingSection(null));

    if (type === "droppableLecture") {
      return handleDropLecture(source, destination);
    }

    if (type === "droppableSection") {
      return handleDropSection(source, destination);
    }

    // TODO: open the sections again that have been open before.
  }

  function handleItemSelect(
    sectionIndex: number,
    itemIndex: string,
    itemId: string
  ) {
    clearEditorContent();

    dispatch(toggleLoadingProject());

    dispatch(selectLecture({ sectionIndex, itemId, itemIndex }));

    dispatch(
      fetchLectureById({
        id: itemId,
        contentType: "lecture",
      }) as unknown as AnyAction
    )
      .then(({ payload }: { payload: DigestState }) => {
        // Updates the editor content
        handleReplaceContent(payload.script);
        dispatch(toggleLoadingProject());
      })
      .catch((err: Error) => {
        console.error(err);
      });
  }

  if (!sections) {
    return null;
  }

  return (
    <div
      className={styles.lecturePanel}
      style={{
        display: panelWidth <= 2 ? "none" : "block",
      }}
    >
      <div className={styles.content}>
        <LecturePanelHeader toggleLayout={toggleLayout} progress={progress} />
        <div className={styles.listContainer}>
          <div className={styles.list}>
            <LectureList
              onItemDelete={handleItemDelete}
              onSectionDelete={handleSectionDelete}
              onSectionAdd={handleSectionAdd}
              onItemAdd={handleItemAdd}
              onItemNameChange={handleItemNameChange}
              onSectionNameChange={handleSectionNameChange}
              onItemSelect={handleItemSelect}
              sections={sections}
              loadingProject={loadingProject}
              onDragEnd={handleDragEnd}
            />
          </div>
          <Divider style={{ marginBottom: "20px", marginTop: "0px" }}>
            <WithToolTip tooltip="Create New Section" trigger="hover">
              <IconButton
                onClick={() => handleSectionAdd()}
                appearance="primary"
                circle
                size="sm"
                icon={<PlusIcon />}
              />
            </WithToolTip>
          </Divider>
          <LecturePanelFooter />
        </div>
      </div>
    </div>
  );
};

export default LecturePanel;
