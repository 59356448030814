// import { useRef } from "react";
import { IconButton } from "rsuite";
// import { Popover, Dropdown, Whisper} from "rsuite";

// import { menuTypeMap } from "../../../DataTypes/dataTypes";

import { IoClose } from "react-icons/io5";
// import { SlOptionsVertical } from "react-icons/sl";

import styles from "../ScriptItem.module.scss";

// const SceneMenu = ({ block, contentType, handleMenuClick }) => {
//   // TODO: indicate that this worked.

//   return (
//     <Dropdown.Menu
//       onSelect={(item) => {
//         handleMenuClick(item);
//       }}
//     >
//       <Dropdown.Item eventKey={{ save: "0" }}>Save to keep</Dropdown.Item>
//       {/* <SceneTypeMenu /> */}
//     </Dropdown.Menu>
//   );
// };

const ItemHeaderMenu = ({
  // block,
  // sceneIndex,
  // contentType,
  handleClose,
  // handleMenuClick,
}) => {
  // const triggerRef = useRef();

  return (
    <>
      {/* <Whisper
        ref={triggerRef}
        placement="auto"
        full
        trigger="click"
        speaker={
          <Popover className={styles.popover}>
            <SceneMenu
              block={block}
              handleMenuClick={handleMenuClick}
              contentType={contentType}
            />
          </Popover>
        }
      >
        <IconButton
          icon={<SlOptionsVertical />}
          appearance="subtle"
          size="sm"
        />
      </Whisper> */}

      {
        <IconButton
          icon={<IoClose />}
          className={styles.closeBtn}
          appearance="subtle"
          size="sm"
          onClick={() => handleClose()}
        />
      }
    </>
  );
};

export default ItemHeaderMenu;
