import { contentType, projectType } from "../interfaces/types";
import { Project } from "../interfaces/Project";
import { DigestState } from "../interfaces/reduxState";
import { NewProject } from "../interfaces/reduxState";

import {
  newEmptyProject,
  newDefaultProject,
  newDefaultLecture,
  newDefaultCourseProject,
  copyContentDiggest,
  dbNameMap,
} from "../DataTypes/dataTypes";

import uniqid from "uniqid";

interface AddDigestPayload {
  updatedProject: Project;
  project: Project;
  contentType: contentType;
  copyContent: boolean;
}

export const createNewDigest = (
  payload: AddDigestPayload,
  activeDiggest: DigestState
) => {
  const newDigest = { type: payload.contentType, core: false };
  const updatedProject = {
    ...payload.project,
    digests: [...payload.project.digests, newDigest],
  };

  const newBlankDiggest = {
    projectId: payload.project.id,
    blockId: uniqid(),
    contentType: payload.contentType,
  };

  const newDigestContent = payload.copyContent
    ? copyContentDiggest(activeDiggest, payload.contentType)
    : newBlankDiggest;

  const digestType: contentType = payload.contentType;

  return {
    dbName: dbNameMap[digestType].dbName,
    newDigestContent: newDigestContent,
    updatedProject: updatedProject,
  };
};

export const createNewLecture = (lectureId: string) => {
  return {
    ...newDefaultLecture({
      projectId: lectureId,
      blockId: uniqid(),
      name: "New Lecture",
      contentType: "lecture",
    }),
  };
};

interface CreateNewProjectPayload {
  userId: string;
  projectType: projectType;
}
// export const createNewProject = ({type: projectType): NewProject => {
export const createNewProject = ({
  userId,
  projectType,
}: CreateNewProjectPayload): NewProject => {
  const newId = uniqid();

  const newProject: Project = {
    name: newEmptyProject.name,
    id: newId,
    userId: userId,
    digests: [{ type: projectType, core: true }],
  };

  function getDefaultContent(projectType: projectType) {
    switch (projectType) {
      case "script":
        return newDefaultProject({
          projectId: newId,
          blockId: uniqid(),
          contentType: projectType,
        });
      case "article":
        return newDefaultProject({
          projectId: newId,
          blockId: uniqid(),
          contentType: projectType,
        });
      case "course":
        return newDefaultCourseProject({
          projectId: newId,
          sectionId: uniqid(),
          lectureId: uniqid(),
          contentType: projectType,
        });
      default:
        return newDefaultProject({
          projectId: newId,
          blockId: uniqid(),
          contentType: projectType,
        });
    }
  }

  const newContent: any = getDefaultContent(projectType);

  let payloadValue: projectType = projectType;

  return {
    id: newId,
    userId: userId,
    dbName: dbNameMap[payloadValue].dbName,
    // To create new project DB entry
    newProject,
    // To create new content DB entry
    newContent,
  };
};
