import React from "react";
import { IconButton, Placeholder } from "rsuite";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeProject } from "../../../reducers/projectsSlice";

import { projectIconMap } from "../../../DataTypes/dataTypes";
// import { RxCaretDown, RxCaretRight } from "react-icons/rx";

import CloseIcon from "@rsuite/icons/Close";

import styles from "./Projects.module.scss";

const ProjectRow = ({ project, ...props }) => {
  // const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  // const CarretIcon = ({ digests, setOpen }) =>
  //   digests && digests.length > 0 ? (
  //     <IconButton
  //       disabled={project.digests.length === 0}
  //       icon={
  //         open ? (
  //           <RxCaretDown
  //             className={styles.caretIcon}
  //             onClick={() => setOpen((state) => !state)}
  //           />
  //         ) : (
  //           <RxCaretRight
  //             className={styles.caretIcon}
  //             onClick={() => setOpen((state) => !state)}
  //           />
  //         )
  //       }
  //       appearance="subtle"
  //       circle
  //       size="sm"
  //       className={styles.caretBtn}
  //     />
  //   ) : (
  //     <div className={styles.placeholder} />
  //   );

  const coreDigest =
    project.digests && project.digests.find((digest) => digest.core).type;

  const ProjectTypeIcon = coreDigest
    ? projectIconMap[coreDigest]
    : projectIconMap.none;

  return (
    <li key={project.id} className={styles.projectItem} {...props}>
      <div className={styles.projectDetail}>
        {/* <CarretIcon digests={project.digests} setOpen={setOpen} /> */}
        <ProjectTypeIcon className={styles.projectIcon} />
        <Link
          to={`/app/project/${project.id}/${
            project.name
          }/${coreDigest}/${JSON.stringify(project.digests)}`}
          className={styles.link}
        >
          {project.name}
        </Link>

        <IconButton
          icon={<CloseIcon />}
          appearance="subtle"
          circle
          onClick={() => dispatch(removeProject(project.id))}
        />
      </div>
      {/* {open && project.digests && (
        <ul className={styles.projectDigests}>
          {project.digests.map((digest) => {
            return (
              <li key={digest.id} className={styles.digestItem}>
                <Link
                  to={`/app/project/${project.id}/${project.name}/${
                    digest.type
                  }/${JSON.stringify(project.digests)}`}
                  className={styles.link}
                >
                  <span className={styles.digestIcon}>{digest.type}</span>
                  <span className={styles.digestLabel}>{"digest name"}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      )} */}
    </li>
  );
};

const ProjectList = ({ projects, isLoading }) => {
  const list = Object.values(projects);

  const WhileLoading = () => {
    return (
      <li>
        <Placeholder.Graph height={40} active />;
        <Placeholder.Graph height={40} active />;
        <Placeholder.Graph height={40} active />;
        <Placeholder.Graph height={40} active />;
        <Placeholder.Graph height={40} active />;
      </li>
    );
  };

  return (
    <div className={styles.projectContainer}>
      <ul>
        {isLoading ? (
          <WhileLoading />
        ) : (
          <>
            {list.length === 0 && (
              <li className={styles.noProjects}>
                <p className={styles.label}>Create a new project</p>
              </li>
            )}
            {list.map((project) => {
              return <ProjectRow project={project} key={project.id} />;
            })}
          </>
        )}
      </ul>
    </div>
  );
};

export default ProjectList;
