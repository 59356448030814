import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./FeaturesSection.scss";

// import Image from "react-bootstrap/Image";

import { IoPlayOutline } from "react-icons/io5";

function FeaturesSection(props) {
  const items = [
    // {
    //   title: "AI-Generated Course Outlines",
    //   description:
    //     "Leverage the power of AI to create a well-structured course outline. CourseCrafts.ai's intelligent feature helps you organize your content logically, making it effortlessly navigable for your learners.",
    //   image: "https://uploads.divjoy.com/undraw-mind_map_cwng.svg",
    // },
    // {
    //   title: "AI Writing Assistant",
    //   description:
    //     "Let our AI assist you in crafting a compelling script. Elevate the quality of your course content to engage your students like never before.",
    //   image: "https://uploads.divjoy.com/undraw-personal_settings_kihd.svg",
    // },

    {
      title: "On-point Progress Tracking",
      description:
        "Effortlessly track the progress of your course development, from start to finish. Stay updated on the completion status of your course.",
      image: "./progressTracking.mp4",
    },
    {
      title: "Intuitive Content Planning",
      description:
        "Learn how to effectively organize your course content, create outlines, and plan your lessons with ease.",
      image: "./lecturePlanning.mp4",
    },
    {
      title: "Perceive Length Estimation",
      description:
        "Say goodbye to guesswork! Get accurate estimations to perfectly manage the pacing of your course, ensuring an optimal learning journey.",
      image: "./lengthEstimation.mp4",
    },

    {
      title: "Powerful Asset Management",
      description:
        "Managing assets becomes a breeze with CourseCrafts.ai. Easily access and update code snippets, images, animations, and screenshare videos. No more lost or outdated assets!",
      image: "./assetManagement.mp4",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <div className="FeaturesSection__features">
          {items.map((item, index) => (
            <Row className="align-items-center" key={index}>
              <Col xs={12} lg={6}>
                <SectionHeader
                  title={item.title}
                  subtitle={item.description}
                  spaced={true}
                  size={3}
                  className="text-center text-lg-left"
                />
              </Col>
              <Col>
                <figure className="FeaturesSection__image-container">
                  {/* <Image src={item.image} alt={item.title} fluid={true} /> */}
                  <video
                    className="FeaturesSection__video"
                    muted
                    loop
                    playsInline
                    onMouseOver={(event) => event.target.play()}
                    onMouseOut={(event) => event.target.pause()}
                    // poster="<https://ruttl.com/assets/img/index-hero.jpg>"
                  >
                    <source src={item.image} type="video/mp4" />
                  </video>
                  <div className="FeaturesSection__video-button">
                    <IoPlayOutline className="FeaturesSection__play-icon" />
                  </div>
                </figure>
              </Col>
            </Row>
          ))}
        </div>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
