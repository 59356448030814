import React, { useEffect, useState } from "react";
import { Progress as RsuiteProgress } from "rsuite";

const getColor = (percent) => {
  const colorRange = {
    green: "#52c41a",
    blue: "#3385ff",
    red: "#ED7A7A",
    orange: "#ffc85a",
  };

  if (percent === 100) {
    return colorRange.green;
  } else if (percent < 25) {
    return colorRange.red;
  } else if (percent >= 25) {
    return colorRange.orange;
  } else {
    return colorRange.blue;
  }
};

const Progress = ({
  type = "circle",
  width = "20px",
  scenes,

  style = {},
}) => {
  const [counter, setCounter] = useState({});
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (scenes?.length > 0) {
      setCounter(
        scenes.reduce((acc, { progress }) => {
          // aggregate progress
          if (!progress) return acc;

          Object.keys(progress).forEach((key) => {
            if (acc[key] === undefined) {
              acc[key] = 0;
            }
            acc[key] += progress[key];
          });

          return acc;
        }, {})
      );
    }
  }, [scenes]);

  useEffect(() => {
    if (Object.values(counter).length > 0 && scenes) {
      setPercent(
        (Object.values(counter).reduce((acc, count) => acc + count) /
          (scenes.length * Object.values(counter).length)) *
          100
      );
    }
  }, [counter, scenes]);

  const ProcessComp = (type) => {
    switch (type) {
      case "circle":
        return RsuiteProgress.Circle;
      case "line":
        return RsuiteProgress.Line;
      default:
        return RsuiteProgress.Circle;
    }
  };

  const ProgressComp = ProcessComp(type);

  return (
    <ProgressComp
      percent={percent}
      status={percent === 100 ? "success" : null}
      showInfo={false}
      strokeWidth={5}
      strokeColor={getColor(percent)}
      style={{ width: width, ...style }}
    />
  );
};

export default Progress;

const SimpleProgress = ({
  type = "circle",
  width = "20px",
  style = {},
  strokeWidth = 10,
  percent = 0,
}) => {
  // const [percent, setPercent] = useState(0);

  const ProcessComp = (type) => {
    switch (type) {
      case "circle":
        return RsuiteProgress.Circle;
      case "line":
        console.log("line");
        return RsuiteProgress.Line;
      default:
        return RsuiteProgress.Circle;
    }
  };

  const ProgressComp = ProcessComp(type);

  return (
    <ProgressComp
      percent={percent}
      status={percent === 100 ? "success" : null}
      showInfo={false}
      // vertical={true}
      strokeWidth={strokeWidth}
      strokeColor={getColor(percent)}
      style={{ width: width, ...style }}
    />
  );
};

export { SimpleProgress };
