import { useState } from "react";
import { EditorContent } from "@tiptap/react";
import SnippetModal from "../SnippetModal/SnippetModal";

import CreateButton from "./CreateButton";

// import MenuBar from "./MenuBar";
import styles from "./Script.module.scss";

function Script({ editor, onItemCreate, onSnippetInsert }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* <MenuBar editor={editor} /> */}
      <EditorContent editor={editor} className={styles.editor} />
      <div className={styles.iconWrapper}>
        <CreateButton onItemCreate={onItemCreate} handleOpen={handleOpen} />
      </div>
      <SnippetModal
        open={open}
        handleClose={handleClose}
        handleInsert={onSnippetInsert}
      />
    </div>
  );
}

export default Script;
