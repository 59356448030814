import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
  name: "uiState",
  initialState: {
    scriptItemFilters: [],
    modal: { open: false, payload: null },
    selectedScene: { blockId: null, index: -1 },
    selectedLecture: { id: null },
    loadingProject: false,
    draggingSection: "",
  },
  reducers: {
    setFilter: (state, action) => {
      state.scriptItemFilters = action.payload;
    },
    clearFilter: (state) => {
      state.scriptItemFilters = [];
    },
    toggleModalOpen: (state, { payload: { type, open, payload } }) => {
      state.modal = { open, type, payload };
    },
    selectScene: (state, action) => {
      state.selectedScene = action.payload;
    },
    selectLecture: (
      state,
      { payload: { sectionIndex, itemId, itemIndex } }
    ) => {
      state.selectedLecture.id = itemId;
      state.selectedLecture.sectionIndex = sectionIndex;
      state.selectedLecture.itemIndex = itemIndex;
    },
    toggleLoadingProject: (state) => {
      state.loadingProject = !state.loadingProject;
    },
    toggleDraggingSection: (state, action) => {
      state.draggingSection = action.payload;
      // !state.draggingSection;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFilter,
  clearFilter,
  toggleModalOpen,
  selectScene,
  selectLecture,
  resetSelectedScene,
  toggleLoadingProject,
  toggleDraggingSection,
} = uiSlice.actions;

export default uiSlice.reducer;
