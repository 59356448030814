import { FiUser } from "react-icons/fi";
import { TfiList } from "react-icons/tfi";
import { BsWindow } from "react-icons/bs";
import { BiAnalyse, BiMovie } from "react-icons/bi";
import { TbNotes } from "react-icons/tb";
import { BsReverseLayoutTextWindowReverse } from "react-icons/bs";

import uniqid from "uniqid";

import { BsChatLeftText, BsCardText } from "react-icons/bs";

export const sceneIcons = {
  headVideo: FiUser,
  slide: TfiList,
  videoClip: BiMovie,
  screenCapture: BsWindow,
  animation: BiAnalyse,
  new: TbNotes,
};

export const projectIconMap = {
  article: BsCardText,
  script: BsChatLeftText,
  lecture: BsReverseLayoutTextWindowReverse,
  course: BsReverseLayoutTextWindowReverse,
  none: BsCardText,
};

export const scriptProgressStages = {
  write: { label: "Script", value: "write" },
  audio: { label: "Audio", value: "audio" },
  video: { label: "Video", value: "video" },
  assets: { label: "Assets", value: "assets" },
  editing: { label: "Editing", value: "editing" },
};

export const articleProgressStages = {
  write: { label: "Write", value: "write" },
  assets: { label: "Assets", value: "assets" },
};

export const courseProgressStages = {};

const iconStyle = { width: "20px", height: "20px" };

export const paragraphTypes = {
  introduction: {
    Icon: <FiUser style={iconStyle} />,
    label: "Introduction",
    value: "introduction",
  },
  definition: {
    Icon: <FiUser style={iconStyle} />,
    label: "Definition",
    value: "definition",
  },
  example: {
    Icon: <FiUser style={iconStyle} />,
    label: "Example",
    value: "example",
  },
  "best-practice": {
    Icon: <FiUser style={iconStyle} />,
    label: "Best Practice",
    value: "best-practice",
  },
  conclusion: {
    Icon: <FiUser style={iconStyle} />,
    label: "Conclusion",
    value: "conclusion",
  },
  "call-to-action": {
    Icon: <FiUser style={iconStyle} />,
    label: "Call to Action",
    value: "call-to-action",
  },
  new: {
    Icon: <TbNotes style={iconStyle} />,
    label: "New Scene",
    value: "new",
  },
};

export const sceneTypes = {
  headVideo: {
    Icon: <FiUser style={iconStyle} />,
    label: "Head Video",
    value: "headVideo",
  },
  slide: {
    Icon: <TfiList style={iconStyle} />,
    label: "Slide",
    value: "slide",
  },
  videoClip: {
    Icon: <BiMovie style={iconStyle} />,
    label: "Video Clip",
    value: "videoClip",
  },
  screenCapture: {
    Icon: <BsWindow style={iconStyle} />,
    label: "Screen Capture",
    value: "screenCapture",
  },
  animation: {
    Icon: <BiAnalyse style={iconStyle} />,
    label: "Animation",
    value: "animation",
  },
  new: {
    Icon: <TbNotes style={iconStyle} />,
    label: "New Scene",
    value: "new",
  },
};

export const menuTypeMap = {
  article: paragraphTypes,
  script: sceneTypes,
  lecture: sceneTypes,
};

export const reverseTypeIconMap = {
  // Article
  introduction: <FiUser style={iconStyle} />,
  definition: <FiUser style={iconStyle} />,
  example: <FiUser style={iconStyle} />,
  "best-practice": <FiUser style={iconStyle} />,
  conclusion: <FiUser style={iconStyle} />,
  // Scene,
  "call-to-action": <FiUser style={iconStyle} />,
  headVideo: <FiUser style={iconStyle} />,
  slide: <TfiList style={iconStyle} />,
  videoClip: <BiMovie style={iconStyle} />,
  screenCapture: <BsWindow style={iconStyle} />,
  animation: <BiAnalyse style={iconStyle} />,
  // Neutral
  new: <TbNotes style={iconStyle} />,
};

export const contentTypeMap = {
  article: "New Paragraph",
  script: "New Scene",
  lecture: "New Scene",
};

export const progressTypeMap = {
  article: articleProgressStages,
  script: scriptProgressStages,
  lecture: scriptProgressStages,
};

export const Scene = {
  blockId: "",
  name: "",
  pos: "",
  size: "",
  attachements: [],
  progress: {},
};

export const newArticle = {
  id: "",
  contentType: "article",
  script: {
    type: "doc",
    content: [],
  },
  scenes: [],
};

export const newScript = {
  id: "",
  contentType: "script",
  script: { type: "doc", content: [] },
  scenes: [],
};

export const newCourse = {
  id: "",
  contentType: "course",
  wordCount: {
    words: 0,
    read: 0,
    spoken: 0,
  },
  sections: [
    { id: "", title: "New Section", lessons: [{ id: "", title: "Lesson 1" }] },
  ],
};

export const dbNameMap = {
  article: { dbName: "Articles", schema: newArticle },
  script: { dbName: "Scripts", schema: newScript },
  lecture: { dbName: "Lectures", schema: newScript },
  course: { dbName: "Courses", schema: newCourse },
};

export const dbNames = ["Articles", "Scripts", "Lectures"];

export const newEmptyProject = {
  name: "New Project",
  id: "",
  digests: [], // {type: 'article', core: true}
};

export const newEmptyCourseProject = {
  name: "New Course Project",
  id: "",
  digests: [
    {
      type: "section",
      id: "",
      core: false,
      wordCount: {
        words: 0,
        read: 0,
        spoken: 0,
      },
      title: "New Section",
      lessons: [{ type: "lecture", id: "", core: false, title: "New Lecture" }],
    },
  ],
};

export const contentTypes = {
  course: {
    label: "Course",
    value: "course",
    description:
      "A course is a collection of videos that are grouped together. You can publish a course on your website or on a platform like Udemy.",
  },

  script: {
    label: "Video Script",
    value: "script",
    description:
      "A video script is the foundation of your video. It is the text that you will read from when recording your video.",
  },
  article: {
    label: "Blog Article",
    value: "article",
    description:
      "A blog article is a text that you can publish on your website or on a platform like Medium.",
  },
};

export const copyContentDiggest = (originalDigest, newContentType) => {
  return {
    ...originalDigest,
    contentType: newContentType,
    scenes: originalDigest.scenes.map((scene) => {
      return {
        ...scene,
        name: contentTypeMap[newContentType],
        type: "new",
        progress: Object.keys(progressTypeMap[newContentType]).reduce(
          (acc, key) => {
            return { ...acc, [key]: false };
          },
          {}
        ),
      };
    }),
    script: {
      type: "doc",
      content: originalDigest.script.content.map((node) => {
        return {
          ...node,
          attrs: {
            ...node.attrs,
            name: contentTypeMap[newContentType],
          },
        };
      }),
    },
  };
};

export const newDefaultScene = ({
  blockId,
  pos,
  index,
  size,
  type,
  contentType,
}) => ({
  blockId: blockId || null,
  pos: pos || null,
  size: size || null,
  name: contentTypeMap[contentType],
  type: type || "new",
  index: index || 0,
  attachments: [],
  progress: Object.keys(progressTypeMap[contentType]).reduce((acc, key) => {
    return { ...acc, [key]: false };
  }, {}),
});

export const newDefaultScript = ({ blockId, contentType, index }) => ({
  type: "doc",
  content: [
    {
      type: "div",
      attrs: { blockId: blockId, name: contentTypeMap[contentType], index },
      content: [
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: " ",
            },
          ],
        },
      ],
    },
  ],
});

export const newDefaultProject = ({ projectId, blockId, contentType }) => ({
  id: projectId, // this is the projectId
  contentType: contentType,
  script: newDefaultScript({ blockId, contentType, index: 0 }),
  scenes: [
    newDefaultScene({
      blockId: blockId,
      pos: null,
      size: "",
      index: 0,
      contentType: contentType,
    }),
  ],
});

export const newDefaultLecture = ({
  projectId,
  blockId,
  name,
  contentType,
}) => ({
  id: projectId, // this is the projectId
  contentType: contentType,
  name: name || "New Lecture",
  script: newDefaultScript({ blockId, contentType, index: 0 }),
  scenes: [
    newDefaultScene({
      blockId: blockId,
      pos: null,
      size: "",
      index: 0,
      contentType: contentType,
    }),
  ],
});

const createSection = ({ sectionId, lectureId }) => ({
  type: "section",
  id: sectionId,
  core: false,
  title: "New Section",
  lectures: [
    { type: "lecture", id: lectureId, core: false, title: "New Lecture" },
  ],
});

export const newLecture = {
  id: uniqid(),
  title: "New Lecture",
  type: "lecture",
  wordCount: {
    words: 0,
    read: 0,
    spoken: 0,
  },
  core: false,
};

export const createNewLecture = () => ({
  id: uniqid(),
  title: `New Lecture`,
  type: "lecture",
  progress: 0,
  wordCount: {
    words: 0,
    read: 0,
    spoken: 0,
  },
  core: false,
});

export const createNewSection = () => ({
  id: uniqid(),
  core: false,
  progress: 0,
  wordCount: {
    words: 0,
    read: 0,
    spoken: 0,
  },
  type: "section",
  title: "New Section",
  lectures: [createNewLecture()],
});

export const newDefaultCourseProject = ({
  projectId,
  sectionId,
  lectureId,
  contentType,
}) => ({
  id: projectId, // this is the projectId
  contentType: contentType,
  wordCount: {
    words: 0,
    read: 0,
    spoken: 0,
  },
  // sections: [],
  sections: [createSection({ sectionId, lectureId })],
});
