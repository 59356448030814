import { useEffect, useCallback, useState } from "react";
import { Content, FlexboxGrid } from "rsuite";

import { useEditor } from "@tiptap/react";
import { useDispatch, useSelector } from "react-redux";

import { updateScript } from "../../../reducers/projectStateSlice";
import { setFilter } from "../../../reducers/uiSlice";
import defaultContent from "../../../tipTap/defaultContent";

import DigestFooter from "./DigestFooter";

import { resetProject } from "../../../reducers/projectStateSlice";

import ArticleScript from "../Script/Script";
import DigestSideMenu from "./DigestSideMenu";

import { debounce } from "../../../util/appUtils";

import styles from "./digest.module.scss";

let setInitScript = false;

const Digest = ({ id, sideBarExpanded, project, extensions }) => {
  const [layout] = useState([2, 20, 2]);

  const dispatch = useDispatch();
  const { scriptItemFilters } = useSelector((state) => state.uiState);

  const editor = useEditor({
    extensions: extensions,
    editable: true,
    onTransaction: (state) => {},
    // reduces the load on the DB, text doesn't have to be saved every second.
    onUpdate: debounce(({ editor }) => {
      dispatch(updateScript(editor.getJSON()));
    }, 250),
  });

  const handleItemCreate = useCallback(
    (contentType, index) => {
      //TODO: use global dataTypes
      const contentTypeMap = {
        article: "New Paragraph",
        script: "New Scene",
        lecture: "New Scene",
      };

      console.log("handleItemCreate", contentType, index);
      return editor
        .chain()
        .focus("end")
        .scrollIntoView()
        .insertContent({
          ...defaultContent,
          attrs: {
            name: contentTypeMap[contentType],
            contentType,
            // TODO: set index
            index: index,
          },
        })
        .run();
    },
    [editor]
  );

  const handleSnippetInsert = ({ scene, script }) => {
    // TODO: only allows to insert at the end of the script
    return editor
      .chain()
      .focus("end")
      .scrollIntoView()
      .insertContent({
        ...script,
        attrs: { name: scene.name, index: project.activeDiggest.scenes.length },
      })
      .run();
  };

  useEffect(() => {
    console.log("mount");

    return () => {
      console.log("unmounting");
      // On unmount
      dispatch(resetProject());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!editor) return;

    if (
      setInitScript === false &&
      project.activeDiggest?.script?.content.length > 0
    ) {
      setInitScript = true;
      // when the content is passed the first time it's set into the editor.
      editor.commands.setContent(project.activeDiggest?.script?.content);
    } else {
      //TODO: could be redundant
      // Set default script for nex project
      if (!setInitScript && project.activeDiggest?.contentType) {
        console.log("VALIDATE IF NEEDED");
        // handleItemCreate(project.activeDiggest?.contentType);
      }
    }
  }, [project, handleItemCreate, editor]);

  useEffect(() => {
    return () => {
      // On unmount
      setInitScript = false;
    };
  }, []);

  if (!project.activeDiggest) {
    return null;
  }

  return (
    <>
      <FlexboxGrid style={{ overflow: "auto", height: "100%" }}>
        <FlexboxGrid.Item colspan={layout[0]} />
        <FlexboxGrid.Item colspan={layout[1]}>
          <Content className={styles.scriptContainer}>
            <ArticleScript
              editor={editor}
              onItemCreate={() =>
                handleItemCreate(project.activeDiggest?.contentType)
              }
              onSnippetInsert={handleSnippetInsert}
            />
          </Content>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          colspan={layout[2]}
          style={{
            height: "100%",
            position: "fixed",
            right: "0px",
            paddingBottom: "75px",
            fontSize: "15px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <DigestSideMenu
            contentType={project.activeDiggest?.contentType}
            scriptItemFilters={scriptItemFilters}
            onFilterChange={(setFilters) => dispatch(setFilter(setFilters))}
            scenes={project.activeDiggest.scenes}
            characterCount={editor?.storage.characterCount}
            withFooter={project.activeDiggest?.contentType === "script"}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
      {project.activeDiggest?.contentType === "script" && (
        <DigestFooter
          editor={editor}
          sideBarExpanded={sideBarExpanded}
          scenes={project.activeDiggest.scenes}
          scriptItemFilters={scriptItemFilters}
          handleItemCreate={() => {
            handleItemCreate(project.activeDiggest?.contentType);
          }}
        />
      )}
    </>
  );
};

export default Digest;
