import { useEffect, useState } from "react";
// import { Input } from "rsuite";

import { useDispatch, useSelector } from "react-redux";

import { menuTypeMap } from "../../../../DataTypes/dataTypes";
import { addSnippet } from "../../../../reducers/snippetSlice";

import EditableName from "../../EditableName/EditableName";

import {
  updateSceneName,
  updateSceneType,
  updateSceneProgress,
  // updateDiggestProgress,
  removeSceneById,
} from "../../../../reducers/projectStateSlice";

import { updateLectureProgress } from "../../../../reducers/courseStateSlice";

import ItemHeaderMenu from "./ItemHeaderMenu";
import ItemProgress from "./ItemProgress";

import ItemIcon from "./ItemIcon";

import styles from "../ScriptItem.module.scss";

import Attachements from "../../Attachements/Attachements";

function resetProgress(progress) {
  const newProgress = { ...progress };

  for (let key in newProgress) {
    newProgress[key] = false;
  }
  return newProgress;
}

const ItemHeader = ({
  editable,
  block,
  scenes,
  setModalOpen,
  deleteNode,
  content,
  contentType,
}) => {
  const { selectedLecture } = useSelector((state) => state.uiState);
  const dispatch = useDispatch();
  // const [_, setEditName] = useState(false);
  const [sceneIndex, setSceneIndex] = useState(null);

  useEffect(() => {
    if (!scenes || !block) {
      return;
    }

    setSceneIndex(
      scenes.findIndex((scene) => {
        return scene.blockId === block.blockId;
      })
    );
  }, [scenes, block]);

  const handleClose = () => {
    deleteNode();
    dispatch(removeSceneById({ blockId: block.blockId }));

    dispatch(
      updateLectureProgress({
        sectionIndex: selectedLecture.sectionIndex,
        lectureIndex: selectedLecture.itemIndex,
        progress: resetProgress(block.progress),
        sceneId: block.blockId,
        scenes: scenes.filter((scene) => scene.blockId !== block.blockId),
      })
    );
  };

  const handleNameChange = (name) => {
    dispatch(updateSceneName({ blockId: block.blockId, name }));
  };

  const handleLectureProgressUpdate = (selectedLecture, updatedProgress) => {
    dispatch(
      updateLectureProgress({
        sectionIndex: selectedLecture.sectionIndex,
        lectureIndex: selectedLecture.itemIndex,
        progress: updatedProgress,
        sceneId: block.blockId,
        scenes: scenes,
      })
    );
  };

  const handleMenuClick = (menuValue) => {
    switch (Object.keys(menuValue)[0]) {
      case "save":
        const snippetContent = content.find(
          (item) => item.attrs.blockId === block.blockId
        );
        dispatch(addSnippet({ scene: block, script: snippetContent }));
        break;

      // case "title":
      //   setEditName((toggle) => !toggle);
      // break;

      case "type":
        dispatch(
          updateSceneType({
            blockId: block.blockId,
            type: menuTypeMap[contentType][menuValue.type].value,
          })
        );

        break;
      case "stage":
        const updatedProgress = {
          ...block.progress,
          [menuValue.stage]: !block.progress[menuValue.stage],
        };

        // dispatch(
        //   updateDiggestProgress({
        //     id: block.blockId,
        //     progress: updatedProgress,
        //   })
        // );

        if (contentType === "lecture") {
          console.log("update lecture progress", updatedProgress);
          handleLectureProgressUpdate(selectedLecture, updatedProgress);
        }

        dispatch(
          updateSceneProgress({
            blockId: block.blockId,
            progress: updatedProgress,
          })
        );
        break;
      default:
    }
  };

  const Title = ({ onClick }) => (
    <span className={styles.title} onClick={onClick}>
      {block?.name}
    </span>
  );

  return (
    <div className={styles.header}>
      <div className={styles.typeHeader}>
        {block && (
          <>
            <ItemIcon
              block={block}
              contentType={contentType}
              handleMenuClick={handleMenuClick}
            />
            <EditableName
              Title={Title}
              name={block?.name}
              size={"xs"}
              handleChange={(newName) => {
                handleNameChange(newName);
              }}
            />
          </>
        )}
      </div>

      {editable && (
        <div className={styles.actionBar}>
          {/* <Attachments noOfAttachements={null} /> */}
          <Attachements.Button
            setModalOpen={setModalOpen}
            sceneId={block?.blockId}
            scenes={scenes}
          />
          <ItemProgress
            progressObj={block?.progress}
            contentType={contentType}
            handleMenuClick={handleMenuClick}
          />

          <ItemHeaderMenu
            sceneIndex={sceneIndex}
            block={block}
            contentType={contentType}
            handleClose={handleClose}
            handleMenuClick={handleMenuClick}
          />
        </div>
      )}
    </div>
  );
};

export default ItemHeader;
