import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { List, Placeholder } from "rsuite";

import Section from "./Section";

// import styles from "./lectureList.module.scss";

const WithPlaceholder = ({ children, loading }) => {
  if (loading) {
    return <Placeholder.Paragraph rows={10} active />;
  }
  return children;
};

function LectureList({
  sections,
  loadingProject,
  onDragEnd,
  onItemDelete,
  onSectionDelete,
  onSectionAdd,
  onItemAdd,
  onSectionNameChange,
  onItemNameChange,
  onItemSelect,
}) {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <WithPlaceholder loading={!sections.length > 0 && loadingProject}>
        <DragDropContext onDragEnd={onDragEnd}>
          <List
            size="sm"
            style={{
              boxShadow: "none",
              height: "100%",
              marginBottom: "5px",
              padding: "0px 2px",
            }}
          >
            {sections.length > 0 &&
              sections.map((el, sectionIndex) => {
                return (
                  <Droppable
                    key={sectionIndex}
                    droppableId={`${sectionIndex}`}
                    type="droppableSection"
                  >
                    {(provided, snapshot) => (
                      <div
                        key={el.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Section
                          provided={provided}
                          // snapshot={snapshot}
                          section={el}
                          index={sectionIndex}
                          onSectionDelete={() => onSectionDelete(sectionIndex)}
                          onSectionAdd={() => onSectionAdd(sectionIndex)}
                          onItemAdd={() => onItemAdd(sectionIndex)}
                          onItemSelect={(lectureIndex, lectureId) =>
                            onItemSelect(sectionIndex, lectureIndex, lectureId)
                          }
                          onItemDelete={(lectureIndex, lectureId) =>
                            onItemDelete(sectionIndex, lectureIndex, lectureId)
                          }
                          onSectionNameChange={(newName) =>
                            onSectionNameChange(sectionIndex, newName)
                          }
                          onItemNameChange={(
                            lectureIndex,
                            lectureId,
                            newName
                          ) =>
                            onItemNameChange(
                              sectionIndex,
                              lectureIndex,
                              lectureId,
                              newName
                            )
                          }
                        />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                );
              })}
          </List>
        </DragDropContext>
      </WithPlaceholder>
    </div>
  );
}

export default LectureList;
