import { initializeApp, getApps, getApp } from "firebase/app";

let app;
if (getApps().length === 0) {
  // Initialize Firebase app
  app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: "script-hub-ai.appspot.com",
    messagingSenderId: "125049736752",
    appId: "1:125049736752:web:d3bc9c9ec5ef51f9dca7f5",
    measurementId: "G-W7BP5DXN64",
  });
} else {
  // Use existing app if already initialized
  app = getApp();
}

export const firebaseApp = app;
