import { Tooltip, Whisper } from "rsuite";

export default function WithToolTip({
  children,
  placement = "auto",
  trigger = "hover",
  tooltip = "Add a Tooltip",
  arrow = true,
}) {
  return (
    <Whisper
      placement={placement}
      trigger={trigger}
      speaker={<Tooltip arrow={true}>{tooltip}</Tooltip>}
    >
      {children}
    </Whisper>
  );
}
