import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Image from "react-bootstrap/Image";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

import "./SecondaryCTAsection.scss";

function HeroSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <div className="SecondaryCTAsection__features">
          <Row className="align-items-center">
            <Col xs={12} lg={6} className="align-items-center">
              <figure className="SecondaryCTAsection__image-container">
                <Image src={props.image} alt={props.title} fluid={true} />
              </figure>
            </Col>
            <Col xs={12} lg={6}>
              <div className="mr-4 mt-4 mb-4">{props.text}</div>
              {/* <LinkContainer to={props.buttonPath}> */}
              <Button
                variant={props.buttonColor}
                size="md"
                onClick={() => {
                  window.location.href = props.buttonPath;
                }}
              >
                {props.buttonText}
              </Button>
              {/* </LinkContainer> */}
            </Col>
          </Row>
        </div>
      </Container>
    </Section>
  );
}

export default HeroSection;
