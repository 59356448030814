import { useSelector } from "react-redux";

import { reduxState } from "../../../interfaces/reduxState";

import styles from "./TextStats.module.scss";

export default function TextStats({ withFooter }: { withFooter?: boolean }) {
  const course = useSelector(
    ({ courseState }: reduxState) => courseState.course
  );
  const { sectionIndex, itemIndex: lectureIndex } = useSelector(
    ({ uiState }: reduxState) => uiState.selectedLecture
  );

  if (
    !course.sections ||
    sectionIndex === undefined ||
    lectureIndex === undefined
  )
    return null;

  const section = course.sections[sectionIndex || 0];
  const lecture = section.lectures[lectureIndex || 0];

  return (
    <div
      className={styles.textStats}
      style={{ marginBottom: withFooter ? "175px" : "5px" }}
    >
      <span>{lecture?.wordCount?.words || 0} words</span>
      <span>{lecture?.wordCount?.read || 0} min read</span>
      <span>{lecture?.wordCount?.spoken || 0} min spoken</span>
    </div>
  );
}
