// disable all ts errors for this file
// @ts-nocheck

import { getFirestore } from "firebase/firestore";

import {
  collection,
  getDocs,
  addDoc,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
  query,
  where,
} from "firebase/firestore";

import { Project, CourseProject } from "../interfaces/Project";
import { NewProject } from "../interfaces/reduxState";
import { contentType, projectType } from "../interfaces/types";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { firebaseApp } from "../util/firebase";

// Initialize Firebase
const app = firebaseApp;
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);

const dbNameMap = {
  article: { dbName: "Articles" },
  script: { dbName: "Scripts" },
  lecture: { dbName: "Lectures" },
  course: { dbName: "Courses" },
};

/** Projects */

export const addProject = (newProject: Project) =>
  setDoc(doc(db, "Projects", newProject.id), newProject);

export const addProjectWithContent = ({
  id,
  dbName,
  newProject,
  newContent,
}: NewProject) => {
  setDoc(doc(db, "Projects", id), newProject);
  setDoc(doc(db, dbName, id), newContent);
};

interface AddEntryPayload {
  dbName: string;
  id: string;
  newEntry: any;
}

export const addEntry = ({ dbName, id, newEntry }: AddEntryPayload) =>
  setDoc(doc(db, dbName, id), newEntry);

interface UpdateEntryPayload {
  dbName: string;
  updatedEntity: any;
}

export const updateEntity = ({ dbName, updatedEntity }: UpdateEntryPayload) =>
  updateDoc(doc(db, dbName, updatedEntity.id), updatedEntity);

interface UpdateEntityPropertyPayload {
  dbName: string;
  id: string;
  property: string;
  value: any;
}

export const updateEntityProperty = ({
  dbName,
  id,
  property,
  value,
}: UpdateEntityPropertyPayload) =>
  updateDoc(doc(db, dbName, id), { [property]: value });

export const deleteEntry = ({ dbName, id }: { dbName: string; id: string }) =>
  deleteDoc(doc(db, dbName, id));

export const deleteMultipleEntries = ({
  dbName,
  ids,
}: {
  dbName: string;
  ids: string[];
}) => {
  ids.forEach((id) => deleteEntry({ dbName, id }));
};

export const removeProjectWithContent = ({ projectId, dbNames, ...rest }) => {
  deleteDoc(doc(db, "Projects", projectId));
  // Interate through all existing content types and delete entries by id
  dbNames.forEach((dbNames) => {
    deleteDoc(doc(db, dbNames, projectId));
  });
};

// TODO: update this function once legcy code is removed
export const newGetProject = async (id) => {
  const docRef = doc(db, "Projects", id);
  const docSnap = await getDoc(docRef);

  return docSnap.data();
};

export const updateContentDigest = (updatedDigest) => {
  setDoc(
    doc(db, dbNameMap[updatedDigest.contentType].dbName, updatedDigest.id),
    updatedDigest
  );
};

export const removeProject = (projectId) => {
  deleteDoc(doc(db, "Projects", projectId));
  //TODO: Delete all kinds of content related to this project
  deleteDoc(doc(db, "Scripts", projectId));
};

export const updateProjectName = ({ id, name }) => {
  updateDoc(doc(db, "Projects", id), {
    name,
    id,
  });
};

export const updateProjectDigests = ({ id, name, digests }) => {
  setDoc(doc(db, "Projects", id), {
    id,
    name,
    digests,
  });
};

export const addContentDigest = (contentType, newContent) => {
  setDoc(doc(db, contentType, newContent.id), newContent);
};

export const getAllProjects = (userId) => {
  const citiesRef = collection(db, "Projects");
  return getDocs(query(citiesRef, where("userId", "==", userId)));
};

/** Scenes */
export const updateProject = (newProject) => {
  setDoc(doc(db, "Scripts", newProject.id), newProject);
};

export const removeScene = (newProject) => {
  return updateDoc(doc(db, "Scripts", newProject.id), {
    scenes: newProject.scenes,
  });
};

export const createProject = (newProject) =>
  addDoc(collection(db, "Scripts"), newProject);

// Legacy code
export const getProject = async (projectId) => {
  const docRef = doc(db, "Scripts", projectId);
  const docSnap = await getDoc(docRef);

  return docSnap.data();
};

export const getContent = async (
  projectId: string,
  contentType: projectType | contentType
) => {
  const docRef = doc(db, dbNameMap[contentType].dbName, projectId);

  try {
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  } catch (error) {
    console.log(error);
  }
};

/** Snippets */

export const addSnippet = (newSnippet) => {
  // return addDoc(collection(db, "Snippets"), newSnippet);
  return setDoc(doc(db, "Snippets", newSnippet.scene.blockId), newSnippet);
};

export const removeSnippet = (snippetId) => {
  deleteDoc(doc(db, "Snippets", snippetId));
};

export const updateSnippet = (newSnippet) => {
  setDoc(doc(db, "Snippets", newSnippet.id), newSnippet);
};

export const getAllSnippets = () => {
  return getDocs(collection(db, "Snippets"));
};

export const getSnippet = async (snippetId) => {
  const docRef = doc(db, "Snippets", snippetId);
  const docSnap = await getDoc(docRef);

  return docSnap.data();
};

/** Course */

export const updateC = (updatedCourse: CourseProject) => {
  setDoc(doc(db, "Courses", updatedCourse.id), updatedCourse);
};
