import React from "react";
import Meta from "./../components/Meta";
import ContactSection from "./../components/ContactSection";

import PageLayout from "./../components/PageLayout";

function ContactPage(props) {
  return (
    <PageLayout>
      <Meta title="Contact" />
      <ContactSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Contact Us"
        subtitle=""
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
      />
    </PageLayout>
  );
}

export default ContactPage;
