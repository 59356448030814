import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Header, Placeholder } from "rsuite";

import NameEditInput from "./NameEditInput/NameEditInput";
// import DigestSelect from "./DigestSelect/DigestSelect";

import styles from "./projectHeader.module.scss";

const ProjectHeader = ({ id }) => {
  const [editName, setEditName] = useState(false);

  const projectState = useSelector(({ projectState }) => projectState);

  const Headline = ({ name }) => (
    <h2
      className={styles.headline}
      onClick={() => setEditName((toggle) => !toggle)}
    >
      {name}
    </h2>
  );

  // console.log("Header", projectState.name);
  return (
    <Header className={styles.header}>
      <>
        {!editName ? (
          projectState.name ? (
            <Headline name={projectState?.name} />
          ) : (
            <Placeholder.Graph active height={20} width={300} />
          )
        ) : (
          <NameEditInput
            id={id}
            setEditName={setEditName}
            name={projectState?.name}
          />
        )}
      </>
      {/* <DigestSelect /> */}
    </Header>
  );
};

export default ProjectHeader;
