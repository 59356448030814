import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Avatar from "./Avatar";

import { SiUdemy } from "react-icons/si";

function TeamFounderBiosSection(props) {
  const items = [
    {
      avatar: "./fabianhinsenkamp.png",
      name: "Fabian Hinsenkamp",
      role: "Udemy Instructor & Senior Software Engineer",
      bio: "Hi, I'm Fabian, the creator of CourseCrafts. After spending hundreds of hours planning, writing, and editing Udemy courses, I realized the need for a solution that streamlines the course creation process. So, I decided to leverage my experience and expertise to develop CourseCrafts.ai. This platform reflects the efficient production process I've honed over the years, making it easier than ever to deliver engaging courses in a fraction of the time.",

      socialMedia: [
        {
          name: "Twitter",
          url: "https://twitter.com/hinsencamp",
        },
        {
          name: "LinkedIn",
          url: "https://www.linkedin.com/in/fabianhinsenkamp/",
        },
        {
          name: "udemy",
          url: "https://www.udemy.com/user/alexander-kurt/",
        },
      ],
    },
    // {
    //   avatar: "https://uploads.divjoy.com/pravatar-150x-35.jpeg",
    //   name: "Lisa Zinn",
    //   role: "Software Engineer",
    //   bio: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo, corporis totam! Labore reprehenderit beatae magnam animi!",
    // },
    // {
    //   avatar: "https://uploads.divjoy.com/pravatar-150x-16.jpeg",
    //   name: "Diana Low",
    //   role: "Designer",
    //   bio: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo, corporis totam! Labore reprehenderit beatae magnam animi!",
    // },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col
              xs={12}
              md={12}
              lg={12}
              className="py-3 d-flex align-items-stretch"
              key={index}
            >
              <Card border="light">
                <Card.Body className="d-flex flex-column text-center align-items-center p-4">
                  <div>
                    <Avatar src={item.avatar} alt={item.name} size="175px" />
                    <h6 className="font-weight-bold mb-0 mt-4">{item.name}</h6>
                    <small>{item.role}</small>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {item.socialMedia.map((item, index) => (
                        <div
                          key={index}
                          href="#"
                          onClick={() => window.open(item.url, "_blank")}
                          style={{ cursor: "pointer", color: "#663399" }}
                        >
                          <i
                            className={`fab fa-${item.name.toLowerCase()} fa-1x ml-2 mr-2 mt-3 mb-3`}
                          >
                            {item.name === "udemy" && (
                              <SiUdemy
                                style={{
                                  height: "16px",
                                  bottom: "2px",
                                  position: "relative",
                                }}
                              />
                            )}
                          </i>
                        </div>
                      ))}
                    </div>
                  </div>
                  <Card.Text className="ml-4 mr-4 mt-4 ">{item.bio}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default TeamFounderBiosSection;
