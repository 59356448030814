import React from "react";
import Meta from "../../../components/Meta";
import SettingsSection from "../../../components/SettingsSection";
import { useRouter } from "../../../util/router";
import { requireAuth } from "../../../util/auth";
import Layout from "../../../components/appComponents/Layout/Layout";

function SettingsPage(props) {
  const router = useRouter();

  return (
    <Layout>
      <Meta title="Settings" />
      <SettingsSection
        settingRoute={"/app"}
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        section={router.query.section}
        key={router.query.section}
      />
    </Layout>
  );
}

export default requireAuth(SettingsPage);
