import { List, IconButton, Stack } from "rsuite";

import { projectIconMap } from "../../../DataTypes/dataTypes";
import { Lecture } from "../../../interfaces/Project";

import CloseIcon from "@rsuite/icons/Close";
import { RxDragHandleDots2 } from "react-icons/rx";

import { SimpleProgress } from "../Progress/Progress";

import EditableName from "../EditableName/EditableName";

// import { turnocat } from "../../../util/appUtils";

import styles from "./lectureList.module.scss";

interface LectureItemProps {
  item: Lecture;
  provided: any;
  snapshot: any;
  onItemDelete: () => void;
  onItemNameChange: (newName: string) => void;
  onClick: () => void;
  inFocus: boolean;
}

const LectureItem = ({
  item,
  provided,
  snapshot,
  onItemDelete,
  onItemNameChange,
  onClick,
  inFocus,
}: LectureItemProps) => {
  const textColorStyle = inFocus ? "#0000ffb3" : "inherit";
  const iconColorStyle = inFocus ? "#0000ff" : "#989898";

  // onClick is needed to make the title editable
  const Title = ({ onClick }: { onClick: () => void }) => (
    <span
      className={styles.itemName}
      onClick={onClick}
      style={{
        fontWeight: inFocus ? "bold" : "normal",
        color: textColorStyle,
        // whiteSpace: "nowrap",
      }}
    >
      {/* {turnocat(item.title, 32)} */}
      {item.title}
    </span>
  );
  return (
    <List.Item
      ref={provided.innerRef}
      key={item.id}
      {...provided.draggableProps}
      onClick={onClick}
    >
      <Stack
        justifyContent="space-between"
        alignItems="center"
        style={{
          cursor: "default",
        }}
      >
        <Stack alignItems="center">
          <div
            draggable="true"
            data-drag-handle
            {...provided.dragHandleProps}
            className={styles.dragHandle}
          >
            <RxDragHandleDots2 className={styles.dragHandleIcon} />
          </div>
          <projectIconMap.script
            style={{ width: "15px", color: iconColorStyle, marginRight: "5px" }}
          />

          <EditableName
            Title={Title}
            name={item.title}
            size={"xs"}
            handleChange={(newName: string) => {
              onItemNameChange(newName);
            }}
          />
        </Stack>
        <Stack justifyContent="space-between" alignItems="center">
          <SimpleProgress
            percent={item.progress}
            width={"18px"}
            style={{ marginRight: "10px" }}
          />

          <IconButton
            onClick={(event) => {
              event.stopPropagation(); // Prevent event bubbling
              onItemDelete();
            }}
            appearance="subtle"
            circle
            size="xs"
            icon={<CloseIcon />}
          />
        </Stack>
      </Stack>
    </List.Item>
  );
};

export default LectureItem;
