import { Modal } from "rsuite";

import Newsletter from "../../Newsletter";

import styles from "./paywallModal.module.scss";

export default function PaywallModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: (type: string) => void;
}) {
  return (
    <Modal
      backdrop
      keyboard={false}
      open={open}
      onClose={() => {
        handleClose("paywall");
      }}
    >
      <Modal.Header>
        <Modal.Title as={"h2"} className={styles.title}>
          Oops! You've Reached the Word Limit.
        </Modal.Title>
        <span className={styles.modalSubtitle}>
          {/* Attach documents and assets to the script. */}
        </span>
      </Modal.Header>

      <Modal.Body className={styles.body}>
        <div className={styles.text}>
          <p>Oh no! You've hit the free word limit for this course!</p>

          <p>
            CourseCrafts is currently in open beta, and we'll be offering a
            subscription for unlimited words very soon.
          </p>

          <p>
            Join our waiting list now to be the first to know when we launch!
          </p>
        </div>
        <div style={{ padding: "10px 50px" }}>
          <Newsletter
            size="md"
            buttonText="Notify Me."
            buttonColor="primary"
            inputPlaceholder="Enter your email"
            subscribedMessage="Check your email to confirm your subscription! 🎉"
          />
        </div>
        <div className={styles.text}></div>
        <p>
          Be the first to break free from the strict word limit! Subscribers
          will get an unique early bird offer!
        </p>
      </Modal.Body>
      {/* <Modal.Footer></Modal.Footer> */}
    </Modal>
  );
}
