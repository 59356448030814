import { createAsyncThunk, createSlice /*current*/ } from "@reduxjs/toolkit";

import { arrayMoveImmutable } from "array-move";

import { CourseProject, Lecture, Section } from "../interfaces/Project";
import { ScriptProgress } from "../interfaces/Progress";

import { DocumentData } from "firebase/firestore";

import { getContent } from "../state/Firebase";
import { Scene } from "../interfaces/Scene";

const courseProgress = (sections: Section[]) =>
  sections
    .map(({ progress }: { progress: number }) => (progress ? progress : 0))
    .reduce((acc: number, curr: number) => acc + curr, 0) / sections.length;

function sectionProgress(sections: Section[], index: number) {
  if (sections[index].lectures.length === 0) {
    return 0;
  }

  return (
    Object.values(sections[index].lectures).reduce((acc, { progress }) => {
      return acc + progress;
    }, 0) / sections[index].lectures.length
  );
}

function move(
  source: Section,
  destination: Section,
  droppableSource: Droppable,
  droppableDestination: Droppable
) {
  const sourceClone = Array.from(source.lectures);
  const destClone = Array.from(destination.lectures);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: { [key: string]: Lecture[] } = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
}

interface Droppable {
  droppableId: string;
  index: number;
}

interface UpdateLectureProgressProps {
  payload: {
    sectionIndex: number;
    lectureIndex: number;
    progress: ScriptProgress;
    scenesLength: number;
    scenes: Scene[];
    sceneId: string;
  };
}

export const fetchCourseById = createAsyncThunk(
  "courseState/fetchCourseById",
  async (id: string, thunkAPI) => {
    // console.log("try to fetched Course", id);

    return await getContent(id, "course");
  }
);

const initState: CourseProject = {
  id: "",
  name: "",
  type: "course",
  progress: 0,
  sections: [],
  wordCount: {
    words: 0,
    read: 0,
    spoken: 0,
  },
};

export const courseStateSlice = createSlice({
  name: "courseState",
  initialState: { course: initState },
  reducers: {
    updateSectionPosition(state, { payload: { source, destination } }) {
      // dropped outside the list
      if (!destination) {
        return;
      }
      const sourceIndex = +source.droppableId;
      const destinationIndex = +destination.droppableId;

      const items = arrayMoveImmutable(
        state.course.sections,
        sourceIndex,
        destinationIndex
      );

      state.course.sections = items;
    },

    updateLecturePosition(state, { payload: { source, destination } }) {
      // dropped outside the list
      if (!destination) {
        return;
      }
      const sourceIndex = +source.droppableId;
      const destinationIndex = +destination.droppableId;

      const handleIsSameSection = () => {
        const items = arrayMoveImmutable(
          state.course.sections[sourceIndex].lectures,
          source.index,
          destination.index
        );
        const newState = [...state.course.sections];
        newState[sourceIndex] = { ...newState[sourceIndex], lectures: items };

        state.course.sections = newState;
      };

      const handleIsDifferentSection = () => {
        const result = move(
          state.course.sections[sourceIndex],
          state.course.sections[destinationIndex],
          source,
          destination
        );

        const newState = state.course.sections.map(
          (section: Section, index: number) => {
            if (index === sourceIndex) {
              return {
                ...section,
                lectures: result[sourceIndex],
              };
            }
            if (index === destinationIndex) {
              return {
                ...section,
                lectures: result[destinationIndex],
              };
            }

            return section;
          }
        );

        state.course.sections = newState;

        // TODO: Doesn't seem to work anymore.

        state.course.sections[sourceIndex].progress = sectionProgress(
          state.course.sections,
          sourceIndex
        );

        state.course.sections[destinationIndex].progress = sectionProgress(
          state.course.sections,
          destinationIndex
        );
      };

      if (sourceIndex === destinationIndex) {
        handleIsSameSection();
      } else {
        handleIsDifferentSection();
      }
    },

    // When a new script is added the progress of the course needs to be reduced
    addSceneUpdate(
      state,
      { payload: { sectionIndex, lectureIndex, sceneLength, scenes } }
    ) {
      const section = state.course.sections[sectionIndex];
      // const lecture = section.lectures[lectureIndex];

      const percentProgressLecture = (progress: any) => {
        const progressValues: boolean[] = Object.values(progress);
        return (
          (progressValues.reduce((acc, bool) => acc + (bool ? 1 : 0), 0) /
            progressValues.length) *
          100
        );
      };

      const updateLecturePogress = (scenes: Scene[], sceneLength: number) =>
        scenes.reduce((acc: number, scene: Scene) => {
          return acc + percentProgressLecture(scene.progress);
        }, 0) / sceneLength;

      const newSectionProgress = () =>
        section.lectures
          .map(({ progress }) => (progress ? progress : 0))
          .reduce((acc, curr) => acc + curr, 0) / section.lectures.length;

      // Reduce lecture progress
      state.course.sections[sectionIndex].lectures[lectureIndex].progress =
        updateLecturePogress(scenes, sceneLength);

      // console.log("new section progress: ", newSectionProgress());
      // Reduce section progress
      state.course.sections[sectionIndex].progress = newSectionProgress();

      // Reduce the progress of the course
      state.course.progress = courseProgress(state.course.sections);
    },

    updateWordCount: (
      state,
      { payload: { sectionIndex, lectureIndex, currentCount } }
    ) => {
      const section = state.course.sections[sectionIndex];
      const lecture = section.lectures[lectureIndex];

      lecture.wordCount = {
        words: currentCount,
        read: Math.floor(currentCount / 200),
        spoken: Math.floor(currentCount / 130),
      };

      const sectionWordCount = section.lectures.reduce(
        (acc, lecture) => acc + lecture.wordCount.words,
        0
      );

      section.wordCount = {
        words: sectionWordCount,
        read: Math.floor(sectionWordCount / 200),
        spoken: Math.floor(sectionWordCount / 130),
      };

      const courseWordCount = state.course.sections.reduce(
        (acc, section) => acc + section.wordCount.words,
        0
      );

      state.course.wordCount = {
        words: courseWordCount,
        read: Math.floor(courseWordCount / 200),
        spoken: Math.floor(courseWordCount / 130),
      };
    },

    updateLectureProgress(
      state,
      {
        payload: { sectionIndex, lectureIndex, progress, scenes, sceneId },
      }: UpdateLectureProgressProps
    ) {
      const section = state.course.sections[sectionIndex];
      // const lecture = section.lectures[lectureIndex];

      const percentProgressLecture = (progress: any) => {
        const progressValues: boolean[] = Object.values(progress);
        return (
          (progressValues.reduce((acc, bool) => acc + (bool ? 1 : 0), 0) /
            progressValues.length) *
          100
        );
      };

      const updateLecturePogress = () =>
        scenes.reduce((acc, scene) => {
          if (scene.blockId === sceneId) {
            return acc + percentProgressLecture(progress);
          }

          return acc + percentProgressLecture(scene.progress);
        }, 0) / scenes.length;

      const sectionProgress = () =>
        section.lectures
          .map(({ progress }) => (progress ? progress : 0))
          .reduce((acc, curr) => acc + curr, 0) / section.lectures.length;

      console.log("updateLectureProgress", updateLecturePogress());

      state.course.sections[sectionIndex].lectures[lectureIndex].progress =
        updateLecturePogress();
      state.course.sections[sectionIndex].progress = sectionProgress();
      state.course.progress = courseProgress(state.course.sections);
    },

    addLecture(state, { payload: { sectionIndex, newLecture } }) {
      state.course.sections[sectionIndex].lectures.push(newLecture);

      state.course.sections[sectionIndex].progress = sectionProgress(
        state.course.sections,
        sectionIndex
      );

      state.course.progress = courseProgress(state.course.sections);
    },

    deleteLecture: (state, { payload: { sectionIndex, lectureIndex } }) => {
      const section = state.course.sections[sectionIndex];

      section.lectures.splice(lectureIndex, 1);

      // Reduce the progress of the section
      state.course.sections[sectionIndex].progress = sectionProgress(
        state.course.sections,
        sectionIndex
      );

      // Update the progress of the course
      state.course.progress = courseProgress(state.course.sections);
    },

    addSection(state, { payload: { newSection } }) {
      state.course.sections.push(newSection);

      state.course.progress = courseProgress(state.course.sections);
    },

    deleteSection: (state, { payload: { sectionIndex } }) => {
      const { sections } = state.course;

      sections.splice(sectionIndex, 1);

      state.course.progress = courseProgress(state.course.sections);
    },
    updateSectionTitle: (state, { payload: { sectionIndex, newName } }) => {
      state.course.sections[sectionIndex].title = newName;
    },
    updateLectureTitle: (
      state,
      { payload: { sectionIndex, lectureIndex, newName } }
    ) => {
      state.course.sections[sectionIndex].lectures[lectureIndex].title =
        newName;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchCourseById.fulfilled, (state, action) => {
      // Initial data fetch from Firestore
      const course: DocumentData | undefined = action.payload;

      if (!course) return;

      state.course = course as CourseProject;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  updateLectureProgress,
  updateLecturePosition,
  updateSectionPosition,
  addLecture,
  addSceneUpdate,
  addSection,
  deleteLecture,
  deleteSection,
  updateSectionTitle,
  updateLectureTitle,
  updateWordCount,
} = courseStateSlice.actions;

export default courseStateSlice.reducer;
