import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
// import ClientsSection from "./../components/ClientsSection";
// import TestimonialsSection from "./../components/TestimonialsSection";
// import NewsletterSection from "./../components/NewsletterSection";
import SecondaryCTAsection from "./../components/SecondaryCTAsection";

import PageLayout from "./../components/PageLayout";
import TeamBiosSection from "../components/TeamFounterBioSection";
// import PricingSection from "../components/PricingSection";

function IndexPage(props) {
  return (
    <PageLayout>
      <Meta />
      <HeroSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="s"
        subtitle="Delivering more engaging courses in a fraction of the time."
        image="./courseHeader.png"
        buttonText="Join Open Beta!"
        buttonColor="primary"
        buttonPath="/auth/signup"
      />
      {/* <ClientsSection
        bg="light"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
      /> */}
      <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Empower Yourself to Share Your Knowledge"
        // subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud."
      />
      <TeamBiosSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Built and designed by an Udemy Instructor"
        subtitle=""
      />
      <SecondaryCTAsection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Curious about the full story behind CourseCrafts.ai? "
        subtitle=""
        image="./medium-icon.svg"
        text="Check out my in-depth article on Medium that delves into the development process, challenges faced, and the vision driving us forward to to gain deeper insights into the journey behind CourseCrafts.ai."
        buttonText="Read the article"
        buttonColor="secondary"
        buttonPath="https://medium.com/@hinsencamp/from-udemy-instructor-to-faang-engineer-to-indie-hacker-the-coursecrafts-story-eccf5a3fcd06"
      />
      {/* <PricingSection /> */}
      {/* <TestimonialsSection
        bg="light"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      /> */}
      {/* <NewsletterSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      /> */}
    </PageLayout>
  );
}

export default IndexPage;
