import React, { useState } from "react";
import { Modal, Button, Panel } from "rsuite";

import { projectType } from "../../../interfaces/types";
import { contentTypes } from "../../../DataTypes/dataTypes";

interface ContentType {
  label: string;
  value: projectType;
  description: string;
}

interface ProjectModalProps {
  open: boolean;
  handleClose: () => void;
  handleCreate: (selectedContentType: ContentType | "") => void;
}

const NewProjectModal = ({
  open,
  handleClose,
  handleCreate,
}: ProjectModalProps) => {
  const [selectedContentType, setSelectedType] = useState<ContentType | "">("");

  return (
    <Modal open={open} onExit={() => setSelectedType("")} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>Select Content Type</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "10px" }}>
        {Object.values(contentTypes).map((contentType: any) => {
          const isNotCourse = contentType.value !== "course";
          return (
            <Panel
              header={contentType.label}
              bordered
              style={{
                cursor: isNotCourse ? "not-allowed" : "pointer",
                color: isNotCourse ? "#999" : "#000",
                marginBottom: "20px",
                borderRadius: "5px",

                boxShadow:
                  selectedContentType &&
                  selectedContentType.value === contentType.value
                    ? "0px 0px 0px 2px rgba(19,101,255,0.84)"
                    : "none",
              }}
              key={contentType.value}
              onClick={() => {
                if (isNotCourse) return;

                setSelectedType(
                  selectedContentType &&
                    selectedContentType.value === contentType.value
                    ? ""
                    : contentType
                );
              }}
            >
              <p>{contentType.description}</p>
            </Panel>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            handleCreate(selectedContentType);
          }}
          appearance="primary"
        >
          Create New Project
        </Button>
        <Button
          onClick={() => {
            handleClose();
          }}
          appearance="subtle"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewProjectModal;
