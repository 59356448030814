import { forwardRef } from "react";
import FunnelIcon from "@rsuite/icons/Funnel";
import { Whisper, TagPicker, IconButton } from "rsuite";

import { progressTypeMap } from "../../../DataTypes/dataTypes";

import WithToolTip from "../WithToolTip/WithToolTip";

import styles from "./FilterBar.module.scss";

const Overlay = forwardRef(
  (
    { style, onClose, selectedValues, setSelectedValues, data, ...rest },
    ref
  ) => {
    const styles = {
      ...style,
      color: "#000",
      background: "#fff",
      width: 200,
      padding: 10,
      borderRadius: 4,
      position: "absolute",
      border: "1px solid #ddd",
      boxShadow: "0 3px 6px -2px rgba(0, 0, 0, 0.6)",
    };

    return (
      <div {...rest} style={styles} ref={ref}>
        {/* So filters selected = show all, filters selected - only show incompleted */}
        <span style={{ fontSize: "smaller" }}>
          Hide Items with completed...
        </span>
        <TagPicker
          data={data}
          style={{ width: 300 }}
          value={selectedValues}
          onChange={(e) => setSelectedValues(e)}
          // onSelect={(e) => setSelectedValues(e)}
        />
      </div>
    );
  }
);

export default function FilterBar({ contentType, filters, onFilterChange }) {
  const data = contentType
    ? Object.keys(progressTypeMap[contentType]).map((item) => ({
        label: item,
        value: item,
      }))
    : [];

  return (
    <WithToolTip tooltip="Filter by progress" trigger="hover">
      <div className={styles.filterBar}>
        <Whisper
          placement="auto"
          trigger="click"
          speaker={(props, ref) => {
            const { className, left, top, onClose } = props;
            return (
              <Overlay
                style={{ left, top }}
                onClose={onClose}
                data={data}
                className={className}
                ref={ref}
                selectedValues={filters}
                setSelectedValues={onFilterChange}
              />
            );
          }}
        >
          <IconButton
            appearance={filters.length > 0 ? "primary" : "subtle"}
            icon={<FunnelIcon style={{ fontSize: "1.5em" }} />}
          />
        </Whisper>
      </div>
    </WithToolTip>
  );
}
