import React, { useEffect, useRef } from "react";
import { IconButton } from "rsuite";
import { NodeViewWrapper, NodeViewContent } from "@tiptap/react";
import { useSelector, useDispatch } from "react-redux";

import { RxDragHandleDots2 } from "react-icons/rx";
import PlusIcon from "@rsuite/icons/Plus";

import { toggleModalOpen, selectScene } from "../../../reducers/uiSlice";

import defaultContent from "../../../tipTap/defaultContent";

import { calcTime, checkFilterStatus } from "../../../util/appUtils";

import ItemHeader from "./ItemHeader/ItemHeader";

import { updatePosition } from "../../../reducers/projectStateSlice";

import WithToolTip from "../WithToolTip/WithToolTip";

// import ItemFooter from "./ItemFooter/ItemFooter";

import useDeepEffect from "../../../hooks/useDeepEffect";
import styles from "./ScriptItem.module.scss";

export default function ScriptItem(props) {
  const dispatch = useDispatch();
  const [times, setTimes] = React.useState(0);
  const [isNotFiltered, setIsNotFiltered] = React.useState(true);

  // Get the block from the active diggest
  const block = useSelector(({ projectState }) =>
    projectState?.activeDiggest?.scenes.find(
      (scene) => scene.blockId === props.node.attrs.blockId
    )
  );
  const { scriptItemFilters } = useSelector((state) => state.uiState);

  const { contentType } = useSelector(
    ({ projectState }) => projectState.activeDiggest
  );
  const isSelected = useSelector(
    (state) => state.uiState.selectedScene.blockId === props.node.attrs.blockId
  );

  const { node, editor, getPos } = props;

  const initialRender = useRef(true);

  // Triggered when the node is updated
  useEffect(() => {
    // Update the reading time for the scene
    const updateTimeState = (editor) => {
      const wordCount = editor.storage.characterCount.words({
        node: node,
      });
      setTimes({
        reading: calcTime(wordCount).read(),
        spoken: calcTime(wordCount).spoken(),
      });
    };
    updateTimeState(editor);

    // skip update on init load
    if (!initialRender.current) {
      console.log("update position");

      // Add the updated position to the scene and DB.
      dispatch(
        updatePosition({
          blockId: node.attrs.blockId,
          pos: getPos(),
          size: node.nodeSize,
          index: node.attrs.index,
        })
      );
    } else {
      initialRender.current = false;
    }
  }, [editor, node, getPos, dispatch]);

  useDeepEffect(() => {
    //TODO: check if this is the best way to do this. Maybe memoize the function?
    // also triggers when new item is added.
    // deep equality check is expensive
    console.log("filter has changed");
    setIsNotFiltered(checkFilterStatus(block?.progress, scriptItemFilters));
  }, [scriptItemFilters, block?.progress]);

  // return ( TODO: ZenMode
  //   <NodeViewWrapper>
  //     <NodeViewContent />;
  //   </NodeViewWrapper>
  // );
  return (
    <NodeViewWrapper
      className={` ${isSelected ? styles.selected : styles.draggable}
  ${isNotFiltered ? styles.unfiltered : styles.filtered}
      `}
      onClick={() => {
        dispatch(
          selectScene({ blockId: props.node.attrs.blockId, index: block.index })
        );
      }}
    >
      <ItemHeader
        block={block}
        contentType={contentType}
        scenes={props.scenes}
        editable={props.editable}
        deleteNode={props.deleteNode}
        setModalOpen={({ type, ...payload }) =>
          dispatch(
            toggleModalOpen({
              open: true,
              type: "attachements",
              payload: {
                sceneId: props.node.attrs.blockId,
                //TODO: edit or create new.
                type,
                ...payload,
              },
            })
          )
        }
        content={props.editor.getJSON().content}
      />
      <div className={styles.itemBody}>
        <div
          className={styles.draggableHanlde}
          // contentEditable="false"
          draggable="true"
          data-drag-handle
        >
          <RxDragHandleDots2 style={{ width: "25px", height: "25px" }} />
        </div>
        <NodeViewContent className={styles.content} />
      </div>

      <div className={styles.statsFooter}>
        <WithToolTip tooltip="Insert scene below" trigger="hover">
          <IconButton
            className={isSelected ? styles.addScriptBtn : styles.hidden}
            icon={<PlusIcon />}
            color="blue"
            size="xs"
            appearance="subtle"
            circle
            onClick={() => {
              const contentTypeMap = {
                article: "New Paragraph",
                script: "New Scene",
              };

              props.editor
                .chain()
                .focus(props.getPos() + props.node.content.size)
                .scrollIntoView()
                .insertContent({
                  ...defaultContent,
                  attrs: {
                    name: contentTypeMap[props.contentType],
                    // contentType: props.contentType,
                    index: block.index + 1,
                  },
                })
                .run();
            }}
          />
        </WithToolTip>
        <span className={styles.textStats}>{`${times.spoken} spoken`}</span>
      </div>

      {/* <ItemFooter sceneId={props.node.attrs.blockId} editable={true} /> */}
    </NodeViewWrapper>
  );
}
