import FilterBar from "../FilterBar/FilterBar";
import AttachementBar from "../AttachementBar/AttachementBar";

// import { SimpleProgress } from "../Progress/Progress";
// import { useSelector } from "react-redux";

import styles from "./TopActionBar.module.scss";

export default function TopActionBar({
  contentType,
  filters,
  onFilterChange,
  scenes,
}) {
  // const { activeDiggest } = useSelector((state) => state.projectState);

  return (
    <div className={styles.topActionBar}>
      {/* {activeDiggest.contentType !== "course" && (
        <SimpleProgress
          percent={activeDiggest?.totalProgress}
          type={"line"}
          width={"0px"}
          strokeWidth={5}
        />
      )} */}
      {/* TODO: Zenmode Button */}
      <FilterBar
        contentType={contentType}
        filters={filters}
        onFilterChange={onFilterChange}
      />
      <AttachementBar scenes={scenes} />
    </div>
  );
}
