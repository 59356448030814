import { Color } from "@tiptap/extension-color";

// import Document from "@tiptap/extension-document";

import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import StarterKit from "@tiptap/starter-kit";

import SceneNode from "./customNodes/SceneNodes";

import HardBreak from "@tiptap/extension-hard-break";

import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import SnippetPreviewNode from "./customNodes/SnippetPreviewNodes";

export const NoNewLine = Extension.create({
  name: "no_new_line",

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("eventHandler"),
        props: {
          handleKeyDown: (view, event) => {
            if (event.key === "Enter" && !event.shiftKey) {
              console.log("enter pressed", view, event);

              // return true;
            }
          },
          // … and many, many more.
          // Here is the full list: https://prosemirror.net/docs/ref/#view.EditorProps
        },
      }),
    ];
  },
});

// // TODO: make sure there can only be documents with only custom nodes!
// const CustomDocument = Document.extend({
//   // name: "doc",
//   content: "text+",
//   // group: "block",
// });
//https://angelika.me/2023/03/07/how-to-require-document-to-start-with-h1-tiptap/

const extensions = [
  NoNewLine,
  SceneNode,
  // CustomDocument,
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    // document: false,
    // bulletList: {
    //   keepMarks: true,
    //   keepAttributes: true,
    // },
    // orderedList: {
    //   keepMarks: true,
    //   keepAttributes: true,
    // },
    hardBreak: false,
  }),
  HardBreak.extend({
    addKeyboardShortcuts() {
      return {
        //https://github.com/ueberdosis/tiptap/issues/426
        //https://github.com/ueberdosis/tiptap/issues/426
        //
        Enter: () => {
          if (
            this.editor.isActive("orderedList") ||
            this.editor.isActive("bulletList")
          ) {
            return this.editor.chain().createParagraphNear().run();
          }
          return this.editor.commands.setHardBreak();
        },
      };
    },
  }),
];

export default extensions;

export const previewExtensions = [
  NoNewLine,
  SnippetPreviewNode,
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    hardBreak: true,
  }),
];
