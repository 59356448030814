import ItemIcon from "./ItemIcon";

import styles from "./snippetItem.module.scss";

const ItemHeader = ({ name, type }) => {
  const ItemTitle = ({ name }) => {
    return <span className={styles.title}>{name}</span>;
  };

  if (!name) return;

  return (
    <div className={styles.header}>
      <div className={styles.typeHeader}>
        <ItemIcon type={type} />
        <ItemTitle name={name} />
      </div>
    </div>
  );
};

export default ItemHeader;
