import { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { CheckPicker } from "rsuite";

import { IconButton } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";

import { useSelector } from "react-redux";

import Scene from "./Scene";
import { SceneModal } from "./SceneModal";

import { useDispatch } from "react-redux";
import { updateOrder } from "../../../reducers/projectStateSlice";

import styles from "./Scenes.module.scss";

export default function Scenes({ editor, scenes, onItemCreate, filterState }) {
  const dispatch = useDispatch();
  const { selectedScene } = useSelector((state) => state.uiState);

  const [open, setOpen] = useState(false);

  const handleOpen = (item) => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const onItemDelete = (item) => {
    // console.log("onDelete", item);
    // if (item.pos !== undefined) {
    //   editor.commands.deleteRange({ from: item.pos, to: item.pos + item.size });
    //   dispatch(removeSceneById(item));
    // }
  };

  const onItemUpdate = (updatedProps) => {
    // dispatch(updateSceneById(openScene.blockId, updatedProps));
  };

  const onDragEnd = ({ source, destination }) => {
    // // dropped outside the list
    if (!destination) {
      return;
    }
    // // change order of newScenes in editor and scenes
    const newScenes = reorder(
      scenes,
      source.index,
      destination.index,
      source.droppableId,
      destination.droppableId
    );
    const currentEditorState = editor.getJSON();
    const newScript = new Array(currentEditorState.content.length);
    // set new content directly into Editor
    newScenes.forEach((item, index) => {
      newScript[index] = {
        attrs: { blockId: item.blockId },
        type: "div",
        content: currentEditorState.content.find(
          (script) => script.attrs.blockId === item.blockId
        ).content,
      };
    });
    editor.commands.setContent({
      ...currentEditorState,
      content: newScript,
    });
    // // sete new content and scene order in firebase.
    dispatch(updateOrder({ scenes: newScenes, script: newScript }));
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#e4e4ff" : "ghostwhite",
  });

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const SceneStats = ({ selectedScene, scenesLength }) => (
    <div className={styles.scenesStats}>
      {`${
        selectedScene.index !== null ? selectedScene.index + 1 : "-"
      } / ${scenesLength}`}
    </div>
  );

  if (!scenes) return null;

  return (
    // <div className={styles.scenesContainer}>
    <>
      <div className={styles.filterBar}>
        {/* <CheckPicker
          data={Object.values(sceneTypes)}
          searchable={false}
          style={{ width: 224 }}
          // appearance="subtle"
          placeholder="Select scene types"
          placement="topStart"
          defaultValue={filterState}
          onChange={(changed) => {
            setFilteredState(changed);
          }}
        /> */}
        <SceneStats
          selectedScene={selectedScene}
          scenesLength={scenes.length}
        />
      </div>
      <div className={styles.scenes}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            isDragDisabled={false}
            isDropDisabled={false}
            droppableId="droppable"
            direction="horizontal"
            className={styles.droppable}
          >
            {(provided, snapshot) => (
              <div
                className={styles.droppable}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {scenes.length === 0 && (
                  <div className={styles.emptyMessage}>
                    Click the + button to add a new scene
                  </div>
                )}

                {scenes.map((item, index) => (
                  <Draggable
                    key={item.blockId}
                    draggableId={item.blockId}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Scene
                        filterState={filterState}
                        item={item}
                        editor={editor}
                        snapshot={snapshot}
                        provided={provided}
                        handleEdit={handleOpen}
                        handleDelte={onItemDelete}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className={styles.addBtn}>
          <IconButton
            icon={<PlusIcon />}
            color="blue"
            appearance="subtle"
            circle
            onClick={() => {
              onItemCreate();
            }}
          />
        </div>
        <SceneModal
          open={open}
          handleClose={handleClose}
          handleOk={onItemUpdate}
        />
      </div>
    </>
  );
}
