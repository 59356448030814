// import { useEffect, useState } from "react";
import { IconButton, Stack } from "rsuite";

import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";
import AngleDownIcon from "@rsuite/icons/legacy/AngleDown";
import { RxDragHandleDots2 } from "react-icons/rx";

import CloseIcon from "@rsuite/icons/Close";
// import Progress from "../Progress/Progress";
import { SimpleProgress } from "../Progress/Progress";

import EditableName from "../EditableName/EditableName";

import styles from "./lectureList.module.scss";

interface SectionHeaderProps {
  title: string;
  folded: boolean;
  progress: number;
  dragHandleProps: any;
  onFold: () => void;
  onDragStart: (wantedState?: boolean) => void;
  onSectionNameChange: (newName: string) => void;
  onSectionDelete: () => void;
}

const SectionHeader = ({
  title,
  progress,
  folded,
  dragHandleProps,
  onFold,
  onDragStart,
  onSectionDelete,
  onSectionNameChange,
}: SectionHeaderProps) => {
  // const [name, setName] = useState(title);

  // onClick is needed to make the title editable
  const Title = ({ onClick }: { onClick: () => void }) => (
    <span
      className={styles.sectionName}
      onClick={onClick}
      style={
        {
          // color: folded ? "#989898" : "#000000",
        }
      }
    >
      {/* {turnocat(name, 20)} */}
      {title}
    </span>
  );

  // TODO: renders too often: console.log("SectionHeader rendered", progress);

  return (
    <header className={styles.sectionHeader}>
      <Stack justifyContent="space-between" alignItems="center">
        <Stack alignItems="center">
          <div
            draggable="true"
            data-drag-handle
            {...dragHandleProps}
            className={styles.sectionDragHandle}
          >
            <RxDragHandleDots2
              className={styles.dragHandleIcon}
              onMouseDown={() => {
                onDragStart(true);
              }}
              onMouseUp={() => {
                onDragStart(false);
              }}
            />
          </div>
          <IconButton
            onClick={() => onFold()}
            appearance="subtle"
            size="sm"
            icon={folded ? <AngleRightIcon /> : <AngleDownIcon />}
          />

          <EditableName
            Title={Title}
            name={title}
            size="xs"
            handleChange={(newName: string) => onSectionNameChange(newName)}
          />
        </Stack>
        <Stack alignItems="center">
          <SimpleProgress
            percent={progress}
            width={"20px"}
            strokeWidth={20}
            style={{ marginRight: "10px" }}
          />
          <IconButton
            onClick={() => {
              onSectionDelete();
            }}
            appearance="subtle"
            circle
            size="xs"
            icon={<CloseIcon />}
          />
        </Stack>
      </Stack>
    </header>
  );
};

export default SectionHeader;
