import { Stack } from "rsuite";

import { useSelector } from "react-redux";
// import { useDispatch,  } from "react-redux";

import { reduxState } from "../../../interfaces/reduxState";
// import { useAuth } from "../../../util/auth";
import { maxWordCount } from "../../../util/prices";

// import { toggleModalOpen } from "../../../reducers/uiSlice";

import styles from "./lecturePanel.module.scss";

const addSpaceDot = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

// a function that changes the color of the input string based on distance of the word count from the max word count
const colorWordCount = (wordCount: number, maxWordCount: number) => {
  if (wordCount < maxWordCount * 0.75) {
    return "#4caf50";
  } else if (wordCount < maxWordCount * 0.95) {
    return "#ffb300";
  } else {
    return "#f44336";
  }
};

const LecturePanelFooter = () => {
  // const dispatch = useDispatch();

  const { wordCount } = useSelector(
    ({ courseState }: reduxState) => courseState.course
  );

  // const auth = useAuth();
  // const stripePriceId: string = auth.user.stripePriceId;
  // const maxWordCount: number = maxWordCounts[stripePriceId];
  // const maxWordCount: number = 1000;

  return (
    <footer className={styles.footer}>
      <Stack justifyContent="flex-end">
        <span
          className={styles.footerText}
          style={{
            fontWeight: 700,
            color: colorWordCount(wordCount?.words || 0, maxWordCount),
            // cursor: "pointer",
          }}
          // onClick={() => {
          //   dispatch(toggleModalOpen({ type: "paywall", open: true }));
          // }}
        >
          {wordCount?.words || 0} / {addSpaceDot(maxWordCount)} words
        </span>
        <span className={styles.footerText}>{"  |  "}</span>
        <span className={styles.footerText}>
          {wordCount?.spoken || 0} mind spoken
        </span>
      </Stack>
    </footer>
  );
};

export default LecturePanelFooter;
