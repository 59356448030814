import { Modal, Button } from "rsuite";
import { Whisper, Popover, Divider } from "rsuite";

import { Input, InputGroup } from "rsuite";

import { useRef, useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import AttachmentIcon from "@rsuite/icons/Attachment";

import uniqid from "uniqid";

import AttachementList from "../AttachementList/AttachementList";

import {
  addAttachment,
  updateAttachment,
  deleteAttachment,
} from "../../../reducers/projectStateSlice";

import { BiLinkAlt } from "react-icons/bi";
import { MdTitle } from "react-icons/md";

import styles from "./attachements.module.scss";

const modalTypes = {
  create: "create",
  edit: "edit",
};

// TODO: Add edit payload to setModalOpen.
function ItemAttachementButton({ setModalOpen, sceneId, scenes }) {
  const triggerRef = useRef();
  const close = () => triggerRef.current.close();
  const dispatch = useDispatch();

  const [attachements, setAttachements] = useState([]);

  const onItemDelete = (id) =>
    dispatch(deleteAttachment({ sceneId, attachementId: id }));

  const onItemUpdate = (id, title, url) => {
    // TODO: open modal with current values.
    // dispatch(deleteAttachment({ sceneId, attachementId: id }));

    setModalOpen({
      type: modalTypes.edit,
      attachementId: id,
      defaultTitle: title,
      defaultUrl: url,
    });
  };

  useEffect(() => {
    if (sceneId && scenes) {
      const scene = scenes.find((scene) => scene.blockId === sceneId);

      if (scene?.attachments) {
        setAttachements(scene.attachments);
      }
    }
  }, [scenes, setAttachements, sceneId]);

  if (attachements.length === 0) {
    return (
      <Button
        className={styles.attachementButton}
        appearance="subtle"
        endIcon={<AttachmentIcon style={{ width: "18px", height: "18px" }} />}
        size="sm"
        onClick={() => setModalOpen({ type: modalTypes.create })}
      />
    );
  }

  return (
    <div className={styles.attachementDropdown}>
      <Whisper
        placement="auto"
        trigger="click"
        ref={triggerRef}
        speaker={
          <Popover className={styles.popover} title={"Attachments"}>
            <AttachementList
              attachements={attachements}
              onItemDelete={onItemDelete}
              onItemUpdate={onItemUpdate}
              onClose={close}
            />
            <Divider style={{ margin: "10px 0" }} />
            <Button
              appearance="primary"
              size="sm"
              onClick={() => {
                close();
                setModalOpen({ type: modalTypes.create });
              }}
            >
              Add
            </Button>
          </Popover>
        }
      >
        <Button
          //   style={{ color: noOfAttachements > 0 && "#1675e0" }}
          className={styles.attachementButton}
          appearance="subtle"
          endIcon={<AttachmentIcon style={{ width: "18px", height: "18px" }} />}
          size="sm"
        >
          {`${attachements.length}`}
        </Button>
      </Whisper>
    </div>
  );
}

function ItemAttachementModal({
  open,
  handleClose,
  sceneId,
  attachementId,
  type /* create / edit*/,
  defaultTitle,
  defaultUrl,
}) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState(defaultTitle || "");
  const [url, setUrl] = useState(defaultUrl || "");

  useEffect(() => {
    setTitle(defaultTitle || "");
    setUrl(defaultUrl || "");

    // return () => {
    //   setTitle("");
    //   setUrl("");
    // };
  }, [defaultTitle, defaultUrl]);

  const onAttachementCreate = (title, url) =>
    dispatch(
      addAttachment({
        sceneId,
        newAttachement: {
          id: uniqid(),
          type: "link",
          title: title,
          url: url,
        },
      })
    );

  const onAttachementUpdate = (title, url) =>
    dispatch(
      updateAttachment({
        sceneId,
        newAttachement: {
          id: attachementId,
          type: "link",
          title: title,
          url: url,
        },
      })
    );

  const actionMap = {
    [modalTypes.create]: {
      action: onAttachementCreate,
      title: "Add new Attachement",
    },
    [modalTypes.edit]: {
      action: onAttachementUpdate,
      title: "Edit Attachement",
    },
  };

  return (
    <Modal
      backdrop
      keyboard={false}
      open={open}
      onClose={() => {
        setTitle("");
        setUrl("");

        handleClose("attachements");
      }}
    >
      <Modal.Header>
        <Modal.Title as={"h2"}>{actionMap[type]?.title}</Modal.Title>
        <span className={styles.modalSubtitle}>
          {/* Attach documents and assets to the script. */}
        </span>
      </Modal.Header>

      <Modal.Body className={styles.body}>
        <InputGroup className={styles.input}>
          <InputGroup.Addon>
            <MdTitle />
          </InputGroup.Addon>
          <Input
            placeholder="Title"
            value={title}
            onChange={(value) => setTitle(value)}
          />
        </InputGroup>
        <InputGroup>
          <InputGroup.Addon>
            <BiLinkAlt />
          </InputGroup.Addon>
          <Input
            placeholder="URL"
            value={url}
            onChange={(value) => setUrl(value)}
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            handleClose("attachements");
            setTitle("");
            setUrl("");

            actionMap[type].action(title, url);
          }}
          appearance="primary"
          disabled={
            type === modalTypes.create
              ? !title || !url
              : !title || !url || !defaultTitle || !defaultUrl
          }
        >
          Ok
        </Button>
        <Button
          onClick={() => {
            setTitle("");
            setUrl("");

            handleClose("attachements");
          }}
          appearance="subtle"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const exportObj = {
  Button: ItemAttachementButton,
  Modal: ItemAttachementModal,
};

export default exportObj;
