import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { DocumentData } from "firebase/firestore";

import { newGetProject, getContent } from "../state/Firebase";

import { Scene } from "../interfaces/Scene";

import {
  scriptProgressStages,
  articleProgressStages,
} from "../DataTypes/dataTypes";

import { projectType } from "../interfaces/types";

interface Diggest {
  script: Script;
  scenes: Array<Scene>;

  // TODO: DigestType could be broken as I had to assign | "" to it
  type: projectType | "";
}

interface Script {
  type: string;
  content: Array<Object>;
}

interface Project {
  id: string;
  name: string;
  activeDiggest: Diggest;
}

// interface updateDiggestProgressProps {
//   payload: {
//     id: string;
//     progress: ScriptProgress;
//   };
// }

//TODO: any Excape hatch used, need to be fixed
const syncScriptAndScenes = ({ script, scenes, ...rest }: any) => {
  const { content } = script;
  const scriptSceneIds: string[] = content.map(
    (scriptItem: any) => scriptItem.attrs && scriptItem.attrs.blockId
  );

  const scenesWithScript = scenes.reduce((acc: Scene[], scene: Scene) => {
    if (scriptSceneIds.includes(scene.blockId)) {
      acc.push(scene);
    }

    return acc;
  }, []);

  return { ...rest, script, scenes: scenesWithScript };
};

interface fetchByIdPayload {
  id: string;
  contentType: projectType;
}

// First, create the thunk
export const fetchById = createAsyncThunk(
  "projectState/fetchById",
  async ({ id, contentType }: fetchByIdPayload, thunkAPI) => {
    return await Promise.all([
      // fetch Project by id, return id + name
      newGetProject(id),
      getContent(id, contentType),
      // fetch current digest by id, contentType, return id + content
    ]);
  }
);

export const fetchProjectById = createAsyncThunk(
  "projectState/fetchProjectById",
  async (id: string, thunkAPI) => {
    return await newGetProject(id);
  }
);

// First, create the thunk
export const fetchLectureById = createAsyncThunk(
  "projectState/fetchLectureById",
  async ({ id, contentType }: fetchByIdPayload, thunkAPI) => {
    return await getContent(id, contentType);
  }
);

const initialState: Project = {
  // name: "default",
  id: "",
  name: "",
  activeDiggest: {
    //TODO: course project layout is different.
    script: {
      type: "doc",
      content: [],
    },

    scenes: [],
    type: "",
  },
};

interface ProgressSchemas {
  article: { [key: string]: boolean };
  script: { [key: string]: boolean };
  lecture: { [key: string]: boolean };
  course: { [key: string]: boolean };
  empty: {};
}

//TODO: Object.keys is not unique to the type, need to be fixed
const progressSchemas: ProgressSchemas = {
  article: Object.keys(articleProgressStages).reduce(
    (acc, key) => ({ ...acc, [key]: false }),
    {}
  ),
  script: Object.keys(scriptProgressStages).reduce(
    (acc, key) => ({ ...acc, [key]: false }),
    {}
  ),
  lecture: Object.keys(scriptProgressStages).reduce(
    (acc, key) => ({ ...acc, [key]: false }),
    {}
  ),
  course: Object.keys(scriptProgressStages).reduce(
    (acc, key) => ({ ...acc, [key]: false }),
    {}
  ),
  empty: {},
};

export const projectSlice = createSlice({
  name: "projectState",
  initialState,
  reducers: {
    // update the name of the project
    updateName: (project, { payload }) => {
      project.name = payload.name;
    },
    // Update the state with the new order of the scenes after drag & drop
    updateOrder: (project, { payload }) => {
      project.activeDiggest.script = {
        type: "doc",
        content: [...payload.script],
      };
      project.activeDiggest.scenes = [...payload.scenes];
    },

    updateSceneName: (project, { payload: { blockId, name } }) => {
      const sceneIndex = project.activeDiggest.scenes.findIndex(
        (scene) => scene.blockId === blockId
      );
      project.activeDiggest.scenes[sceneIndex].name = name;
    },

    updateSceneType: (project, { payload: { blockId, type } }) => {
      const sceneIndex = project.activeDiggest.scenes.findIndex(
        (scene) => scene.blockId === blockId
      );
      project.activeDiggest.scenes[sceneIndex].type = type;
    },

    addAttachment: (project, { payload: { sceneId, newAttachement } }) => {
      const scene = project.activeDiggest.scenes.find(
        (scene) => scene.blockId === sceneId
      );

      if (!scene) return;

      scene.attachments.push(newAttachement);
    },

    updateAttachment: (project, { payload: { sceneId, newAttachement } }) => {
      // update an attachment by id
      const scene = project.activeDiggest.scenes.find(
        (scene) => scene.blockId === sceneId
      );

      if (!scene) return;

      scene.attachments[
        scene.attachments.findIndex(
          (attachement) => attachement.id === newAttachement.id
        )
      ] = newAttachement;
    },

    deleteAttachment: (project, { payload: { sceneId, attachmentId } }) => {
      const scene = project.activeDiggest.scenes.find(
        (scene) => scene.blockId === sceneId
      );

      if (!scene) return;

      scene.attachments.splice(
        scene.attachments.findIndex(
          (attachement) => attachement.id === attachmentId
        ),
        1
      );
    },

    updateSceneProgress: (project, { payload: { blockId, progress } }) => {
      const sceneIndex = project.activeDiggest.scenes.findIndex(
        (scene) => scene.blockId === blockId
      );
      project.activeDiggest.scenes[sceneIndex].progress = progress;
    },

    //TODO: Only relevant for projects that don't have a course structure.
    // updateDiggestProgress: (
    //   project,
    //   { payload: { id, progress } }: updateDiggestProgressProps
    // ) => {
    //   const value =
    //     Object.values(progress).filter((stage) => stage === true).length /
    //     Object.keys(progress).length;

    //   const updatedProgress = {
    //     ...project.activeDiggest.progress,
    //     [id]: value,
    //   };

    //   const totalProgress =
    //     Object.values(updatedProgress).reduce(
    //       (acc: number, curr: boolean) => acc + (curr ? 1 : 0),
    //       0
    //     ) / Object.keys(updatedProgress).length;

    //   project.activeDiggest.progress = updatedProgress;
    //   project.activeDiggest.totalProgress = totalProgress * 100;
    // },

    // add metadata once script has been created by editor
    addScene: (
      project,
      { payload: { blockId, pos, size, name, contentType, index } }
    ) => {
      // const payload = { blockId, pos, size, name, contentType, index };

      const foo: projectType = contentType;

      const newScene: Scene = {
        blockId: blockId || null,
        pos: pos || null,
        size: size || null,
        name: name || "New Scene",
        index: index,
        type: "new",
        attachments: [],
        progress: progressSchemas[foo],
      };

      project.activeDiggest = {
        ...project.activeDiggest,
        scenes:
          index === null
            ? [...project.activeDiggest.scenes, newScene]
            : [
                ...project.activeDiggest.scenes.slice(0, index),
                newScene,
                ...project.activeDiggest.scenes.slice(index),
              ],
      };
    },

    updatePosition: (project, { payload: { blockId, size, pos, index } }) => {
      //TODO: could cause a bug when then index changes as the blockId is
      // not used to identify the scene

      // const sceneIndex = project.activeDiggest.scenes.findIndex(
      //   (scene) => scene.blockId === blockId
      // );

      // when creating the first scene, it wants to update but it doesn't exist yet in scenes
      if (index === null || project.activeDiggest.scenes[index] === undefined)
        return;

      project.activeDiggest.scenes[index].index = index;
      project.activeDiggest.scenes[index].pos = pos;
      project.activeDiggest.scenes[index].size = size;
    },

    removeSceneById: (project, { payload: { blockId } }) => {
      project.activeDiggest.scenes = project.activeDiggest.scenes.filter(
        (scene) => scene.blockId !== blockId
      );
    },

    updateScript: (project, { payload }) => {
      // check to keep scripts and scenes in sync, required as editor is unstable still.

      project.activeDiggest = syncScriptAndScenes({
        ...project.activeDiggest,
        script: payload,
      });
    },

    updateScenes: (project, { payload }) => {
      project.activeDiggest = {
        ...project.activeDiggest,
        scenes: payload,
      };
    },

    // toggle different types of active Diggests
    updateActiveDiggest: (project, { payload }) => {
      project.activeDiggest = payload;
    },

    resetProject: (project) => {
      console.log("resetProject");
      project.id = "";
      project.name = "";
      project.activeDiggest = {} as Diggest;
    },

    resetActiveDiggest: (project) => {
      project.activeDiggest = initialState.activeDiggest as Diggest;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchById.fulfilled, (state, action) => {
      // Initial data fetch from Firestore
      const [project, diggest]: [
        DocumentData | undefined,
        DocumentData | undefined
      ] = action.payload;

      if (!project || !diggest) return;

      state.id = project.id;
      state.name = project.name;
      state.activeDiggest = diggest as Diggest;
    });

    builder.addCase(fetchLectureById.fulfilled, (state, action) => {
      const lecture: DocumentData | undefined = action.payload;

      if (!lecture) {
        return;
      }

      state.activeDiggest = lecture as Diggest;
    });

    builder.addCase(fetchProjectById.fulfilled, (state, action) => {
      const project: DocumentData | undefined = action.payload;

      if (!project) {
        return;
      }

      state.name = project.name || "";
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  updatePosition,
  updateName,
  addScene,
  removeSceneById,
  updateScript,
  updateScenes,
  updateActiveDiggest,
  resetActiveDiggest,
  resetProject,
  updateSceneName,
  updateSceneType,
  updateOrder,
  addAttachment,
  updateAttachment,
  deleteAttachment,
  updateSceneProgress,
  // updateDiggestProgress,
} = projectSlice.actions;

export default projectSlice.reducer;
