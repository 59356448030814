import { useEffect } from "react";
import { Node } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import uniqid from "uniqid";
import { arrayMoveImmutable } from "array-move";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { ReactNodeViewRenderer } from "@tiptap/react";

import ScriptItem from "../components/appComponents/ScriptItem/ScriptItem";

import { addSceneUpdate } from "../reducers/courseStateSlice";
import { addScene } from "../reducers/projectStateSlice";
import { selectScene } from "../reducers/uiSlice";
import { updateScenes } from "../reducers/projectStateSlice";

import store from "../state/reduxStore";

// custom function to align the scenes.
const alignSceneOrder = (node, index) => {
  // Find the existing scene in the state.
  const {
    projectState: {
      activeDiggest: { scenes },
    },
  } = store.getState();

  if (scenes === undefined) return;

  if (scenes[index] === undefined) return;

  if (scenes[index].blockId !== node.attrs.blockId) {
    // find the index of the scene in the array.
    const newIndex = scenes.findIndex(
      (scene) => scene.blockId === node.attrs.blockId
    );

    const updatedScenes = arrayMoveImmutable(scenes, index, newIndex);
    store.dispatch(updateScenes(updatedScenes));
  }
};

const SceneNode = Node.create({
  name: "div",
  draggable: true,
  content: "(paragraph|list?)*",
  group: "block",

  addGlobalAttributes() {
    return [
      {
        types: ["div"],
        attributes: {
          blockId: {
            default: null,
            rendered: true,
            keepOnSplit: false,
          },
          name: {
            default: null,
            rendered: true,
            keepOnSplit: false,
          },
          index: {
            default: null,
            rendered: true,
            keepOnSplit: false,
          },
          //   contentType: {
          //     default: null,
          //     rendered: true,
          //     keepOnSplit: false,
          //   },
        },
      },
    ];
  },

  addProseMirrorPlugins() {
    // let enterCount = 0;
    return [
      new Plugin({
        key: new PluginKey("scene-plugin"),
        props: {
          handleKeyDown: (view, event) => {
            // event.which === 46

            if (event.key === "Backspace") {
              view.state.deleting = true;
            }

            if (event.key === "Enter") {
              //
            }

            return false;
          },
        },

        filterTransaction: (transaction, state) => {
          var isDeleting = state.deleting;

          if (!isDeleting) {
            return true;
          }

          // Only triggers on backspace delete of last character:
          var result = true;

          transaction.mapping.maps.forEach((map) => {
            map.forEach((oldStart, oldEnd, newStart, newEnd) => {
              state.doc.nodesBetween(
                oldStart,
                oldEnd,
                (node, number, pos, parent, index) => {
                  if (node.type.name === "div") {
                    // Duplicate as before every not isDeleting Transaction is approved.
                    if (isDeleting) {
                      // 0 = last char, 2 = multiple chars selected
                      if (newStart === 0 || newStart === 2) {
                        result = false;
                        state.deleting = false;
                      }
                    }
                  }
                }
              );
            });
          });

          return result;
        },

        appendTransaction: (_transactions, oldState, newState) => {
          // always add function back to state scenes.

          if (newState.doc === oldState.doc) {
            return;
          }

          const transaction = newState.tr;

          newState.doc.descendants((node, pos, parent, index) => {
            const { type, attrs } = node;

            // Only process scene nodes.
            if (type.name !== "div" || parent.type.name !== "doc") {
              return;
            }

            // Identify if the node is new are already exists, based on the blockId's existance.
            if (attrs.blockId === null) {
              const blockId = uniqid();
              console.log("NEW SCENE", blockId);
              const newNode = {
                ...node.attrs,
                blockId,
              };

              transaction.setNodeMarkup(pos, undefined, newNode);
            } else {
              // If the node already exists, then we need to update the position and size.

              alignSceneOrder(node, index);

              transaction.setNodeMarkup(pos, undefined, {
                ...node.attrs,
                pos: pos,
                index: index,
                size: node.nodeSize,
              });
            }
          });

          return transaction;
        },
      }),
    ];
  },

  parseHTML() {
    return [{ tag: "div" }];
  },
  renderHTML({ HTMLAttributes }) {
    return ["div", { ...HTMLAttributes, class: "scene" }, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer((props) => {
      const dispatch = useDispatch();
      const { activeDiggest } = useSelector((state) => state.projectState);
      const { selectedLecture } = useSelector((state) => state.uiState);

      useEffect(() => {
        // If there is no active diggest, then we don't need to do anything.
        if (!!activeDiggest["scenes"] === false) return;

        const existingScene = activeDiggest.scenes.find(
          (scene) => scene.blockId === props.node.attrs.blockId
        );

        if (!!existingScene === false) {
          console.log("ADDING SCENE", props.node.attrs);
          dispatch(
            addScene({
              contentType: activeDiggest.contentType,
              blockId: props.node.attrs.blockId,
              pos: props.getPos(),
              size: props.node.nodeSize,
              name: props.node.attrs.name,
              // Important for adding nodes in between.
              index: props.node.attrs.index || null,
            })
          );

          //TODO: why isn't it added when the scene is created?
          dispatch(
            addSceneUpdate({
              sectionIndex: selectedLecture.sectionIndex,
              lectureIndex: selectedLecture.itemIndex,
              sceneLength: activeDiggest.scenes.length + 1,
              scenes: activeDiggest.scenes,
            })
          );

          // Ones the scene is added, we need to select it.
          dispatch(
            selectScene({
              blockId: props.node.attrs.blockId,
              // length is the next index of the array.
              index: props.node.attrs.index || activeDiggest.scenes.length,
            })
          );
        } else {
          // Runs every time the scene is updated.
        }
      }, [activeDiggest, dispatch, props, selectedLecture]);

      return (
        <ScriptItem
          {...props}
          editable={true}
          scenes={activeDiggest.scenes}
          contentType={activeDiggest.contentType}
        />
      );
    });
  },

  onCreate() {
    // The editor is ready.
    // console.log("onCreate", this);
  },
  onUpdate() {
    // The content has changed.
    // console.log("onUpdate", this);
  },
  onSelectionUpdate({ editor }) {
    // The selection has changed.
    // console.log("onSelectionUpdate", this);
  },
  onTransaction({ transaction }) {
    // The editor state has changed.
    // console.log("onTransaction", this);
  },
  onFocus({ event }) {
    // The editor is focused.
    // console.log("onFocus", this);
  },
  onBlur({ event }) {
    // The editor isn’t focused anymore.
    // console.log("onBlur", this);
  },
  onDestroy() {
    // The editor is being destroyed.
    // console.log("onDestroy", this);
  },
});

export default SceneNode;
