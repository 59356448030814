import Scenes from "../Scenes/Scenes";

import { Footer } from "rsuite";

import styles from "./digestFooter.module.scss";

const DigestFooter = ({
  sideBarExpanded,
  editor,
  scenes,
  handleItemCreate,
  scriptItemFilters,
}) => {
  return (
    <Footer className={styles.sceneContainer}>
      <div
        className={
          sideBarExpanded.expand ? styles.scenes : styles.expandedScenes
        }
      >
        <Scenes
          editor={editor}
          scenes={scenes}
          onItemCreate={() => handleItemCreate()}
          filterState={scriptItemFilters}
        />
      </div>
    </Footer>
  );
};

export default DigestFooter;
