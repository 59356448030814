import { updateName } from "../../../../reducers/projectStateSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useRouter } from "../../../../util/router";

import { Input } from "rsuite";

import styles from "./nameEditInput.module.scss";

const NameEditInput = ({ setEditName, name }) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const router = useRouter();

  let { id, content_type, digests } = useParams();

  const handleChange = (value) => {
    console.log("handleChange", value);
    // if (value === "") return;

    dispatch(updateName({ id: id, name: value }));

    if (value === "") {
      value = "Untitled";
      router.push(`/app/project/${id}/${value}/${content_type}/${digests}`);
    }
  };

  return (
    <Input
      value={name}
      autoFocus={true}
      className={styles.headlineInput}
      width={300}
      onKeyDown={(e) => (e.key === "Enter" ? e.target.blur() : null)}
      onChange={handleChange}
      onBlur={(e) => {
        if (e.target.value === "") {
          dispatch(updateName({ id: id, name: "Untitled" }));
        }

        setEditName((toggle) => !toggle);
      }}
    />
  );
};

export default NameEditInput;
