// import { Dropdown } from "rsuite";

// import { useDispatch } from "react-redux";

import { Whisper, Button, Dropdown, Popover } from "rsuite";

import { menuTypeMap } from "../../../../DataTypes/dataTypes";

import styles from "../ScriptItem.module.scss";

const SceneTypeMenu = ({ handleMenuClick, contentType }) => {
  return (
    <Dropdown.Menu
      title="Scene Type"
      onSelect={(item) => {
        handleMenuClick(item);
      }}
    >
      {Object.values(menuTypeMap[contentType])
        .filter((type) => type.value !== "new")
        .map(({ value, Icon, label }) => {
          return (
            <Dropdown.Item
              icon={Icon}
              eventKey={{ type: value }}
              className={styles.itemIcon}
            >
              {"  "}
              <span className={styles.sceneTypesLabel}>{label}</span>
            </Dropdown.Item>
          );
        })}
    </Dropdown.Menu>
  );
};

const TitleIcon = ({ block, contentType, handleMenuClick }) => {
  if (!block || !contentType) return null;

  return (
    <div style={{ width: 20, marginRight: "15px" }}>
      <Whisper
        placement="auto"
        trigger="click"
        speaker={
          <Popover className={styles.popover}>
            <SceneTypeMenu
              handleMenuClick={handleMenuClick}
              contentType={contentType}
            />
          </Popover>
        }
      >
        <Button appearance="subtle" style={{ padding: "7px" }}>
          {menuTypeMap[contentType][block?.type].Icon}
        </Button>
      </Whisper>
    </div>
  );
};

export default TitleIcon;
