import React from "react";
import { IconButton } from "rsuite";

import PlusIcon from "@rsuite/icons/Plus";

import styles from "./Projects.module.scss";

const ProjectsHeader = ({
  handleOpen,
  headline,
}: {
  headline: string;
  handleOpen: () => void;
}) => {
  return (
    <>
      <div className={styles.header}>
        <h2 className={styles.headline}>{headline}</h2>
        <div className={styles.headMenu}>
          <IconButton
            appearance="primary"
            color="blue"
            icon={<PlusIcon />}
            onClick={() => {
              handleOpen();
            }}
          >
            New Project
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default ProjectsHeader;
