import { Node } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { useSelector } from "react-redux";

import { ReactNodeViewRenderer } from "@tiptap/react";

import SnippetItem from "../../components/appComponents/SnippetItem/SnippetItem";

const SnippetPreviewNode = Node.create({
  name: "div",
  draggable: true,
  content: "(paragraph|list?)*",
  group: "block",

  addGlobalAttributes() {
    return [
      {
        types: ["div"],
        attributes: {
          blockId: {
            default: null,
            rendered: true,
            keepOnSplit: false,
          },
          name: {
            default: null,
            rendered: true,
            keepOnSplit: false,
          },
        },
      },
    ];
  },

  addProseMirrorPlugins() {
    // let enterCount = 0;
    return [
      new Plugin({
        key: new PluginKey("preview-plugin"),
        props: {},
      }),
    ];
  },

  parseHTML() {
    return [{ tag: "div" }];
  },
  renderHTML({ HTMLAttributes }) {
    return ["div", { ...HTMLAttributes, class: "scene" }, 0];
  },
  addNodeView() {
    return ReactNodeViewRenderer((props) => {
      const snippets = useSelector(({ snippets }) => snippets);

      const block = snippets[props.node.attrs.blockId];

      return (
        <SnippetItem {...props} block={block.scene} type={block.scene.type} />
      );
    });
  },
});

export default SnippetPreviewNode;
