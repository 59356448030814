import { useState } from "react";

import { Modal, Button, FlexboxGrid, Col } from "rsuite";

import { FiUser } from "react-icons/fi";
import { TfiList, TfiVideoClapper } from "react-icons/tfi";
import { BsLaptop } from "react-icons/bs";
import { BiAnalyse } from "react-icons/bi";

import styles from "./Scenes.module.scss";

export const SceneModal = ({ handleClose, handleOk, open }) => {
  const [sceneType, selectSceneType] = useState(null);

  return (
    <Modal open={!!open} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>Select Scene Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FlexboxGrid>
          <FlexboxGrid.Item
            as={Col}
            colspan={14}
            md={6}
            onClick={() => selectSceneType("headVideo")}
          >
            <div
              className={styles.sceneType}
              style={{
                backgroundColor: `${sceneType === "headVideo" && "lightgrey"}`,
              }}
            >
              <FiUser className={styles.sceneIcon} />
              Head Video
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            as={Col}
            colspan={14}
            md={6}
            onClick={() => selectSceneType("slide")}
          >
            <div
              className={styles.sceneType}
              style={{
                backgroundColor: `${sceneType === "slide" && "lightgrey"}`,
              }}
            >
              <TfiList className={styles.sceneIcon} />
              Slide
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            as={Col}
            colspan={14}
            md={6}
            onClick={() => selectSceneType("videoClip")}
          >
            <div className={styles.sceneType}>
              <TfiVideoClapper className={styles.sceneIcon} />
              Video Clip
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            as={Col}
            colspan={14}
            md={6}
            onClick={() => selectSceneType("screenCapture")}
          >
            <div className={styles.sceneType}>
              <BsLaptop className={styles.sceneIcon} />
              Screen Capture
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            as={Col}
            colspan={14}
            md={6}
            onClick={() => selectSceneType("animation")}
          >
            <div className={styles.sceneType}>
              <BiAnalyse className={styles.sceneIcon} />
              Animation
            </div>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            const label = sceneType.split(/(?=[A-Z])/).join(" ");

            handleOk({
              name: label.charAt(0).toUpperCase() + label.slice(1),
              type: sceneType,
            });
            handleClose();
            selectSceneType(null);
          }}
          appearance="primary"
        >
          Ok
        </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
