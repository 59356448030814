import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectScene } from "../../../reducers/uiSlice";

import { sceneIcons } from "../../../DataTypes/dataTypes";

import styles from "./Scenes.module.scss";
import { useEffect } from "react";

const getItemStyle = (
  isFiltered = false,
  isCurrent,
  isDragging,
  draggableStyle
) => ({
  outline: isCurrent ? "2px solid blue" : "none",
  backgroundColor: `RGBA(218, 219, 221, ${!isFiltered ? "0.5" : "0.1"})`,
  boxShadow: isFiltered && `0 0 0 1px rgb(0 0 0 / 5%)`,
  color: isFiltered ? `RGBA(218, 219, 221, 0.3)` : "#3c484e",
  transition: "background-color 0.3s ease-in-out, color 0.3s ease-in-out",
  // styles we need to apply on draggables
  ...draggableStyle,
});

const turnocat = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  } else {
    return str;
  }
};

const Scene = ({
  filterState,
  item,
  snapshot,
  provided,
  editor,
  handleDelte,
  handleEdit,
}) => {
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const { blockId } = item;

  const { selectedScene } = useSelector((state) => state.uiState);
  const sceneIndex = useSelector((state) => {
    if (state.projectState.activeDiggest.scenes === undefined) {
      return -1;
    }

    return state.projectState.activeDiggest.scenes.findIndex(
      (scene) => scene.blockId === item.blockId
    );
  });

  const isFiltered = filterState.some((type) => {
    return item.progress[type] === true;
  });

  const Icon = sceneIcons[item.type];

  useEffect(() => {
    if (selectedScene.blockId === blockId) {
      myRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "end",
      });
    }
  }, [selectedScene, blockId]);

  return (
    <div
      ref={myRef}
      className={styles.itemContainer}
      onClick={() => {
        editor.commands.focus(item.pos + item.size - 1);
      }}
      onMouseDown={() => {
        dispatch(selectScene({ blockId: item.blockId, index: sceneIndex }));
      }}
    >
      <div
        className={styles.scene}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(
          isFiltered,
          item.blockId === selectedScene.blockId,
          snapshot.isDragging,
          provided.draggableProps.style
        )}
      >
        <Icon className={styles.sceneIcon} />
        <span>{turnocat(item.name, 15)}</span>
      </div>
    </div>
  );
};

export default Scene;
