import { useEffect, useRef } from "react";
import isEqual from "lodash.isequal";

const useDeepEffect = (effect, dependencies) => {
  const previousDependenciesRef = useRef(dependencies);

  useEffect(() => {
    if (!isEqual(previousDependenciesRef.current, dependencies)) {
      effect();
    }

    previousDependenciesRef.current = dependencies;
  }, [dependencies, effect]);
};

export default useDeepEffect;
