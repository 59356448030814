import { useState, useContext, useEffect } from "react";
import { Container } from "rsuite";

import { useParams } from "react-router-dom";

import { requireAuth } from "../../../util/auth";

import { useDispatch, useSelector } from "react-redux";

import ProjectHeader from "../../../components/appComponents/ProjectHeader/ProjectHeader";
import SideBarContext from "../../../context/sidebarContext";

import {
  fetchById,
  fetchLectureById,
  fetchProjectById,
} from "../../../reducers/projectStateSlice";
import { fetchCourseById } from "../../../reducers/courseStateSlice";
import {
  selectLecture,
  toggleModalOpen,
  toggleLoadingProject,
} from "../../../reducers/uiSlice";

import Attachements from "../../../components/appComponents/Attachements/Attachements";
import tipTapArticleExtension from "../../../tipTap/tipTapArticleExtension";
import ArticleDigest from "../../../components/appComponents/Digest/ArticleDigest";
import DigestCourse from "../../../components/appComponents/Digest/CourseDigest/CourseDigest";
import ScriptDigest from "../../../components/appComponents/Digest/ScriptDigest";

import Layout from "../../../components/appComponents/Layout/Layout";

import Meta from "../../../components/Meta";

import PaywallModal from "../../../components/appComponents/PaywallModal/PaywallModal";
import TutorialModal from "../../../components/appComponents/TutorialModal/TutorialModal";

import styles from "./Project.module.scss";

const ProjectView = () => {
  const dispatch = useDispatch();

  const sideBarExpanded = useContext(SideBarContext);
  const { id, content_type } = useParams();

  const [selectedDigest, setSelectedDigest] = useState({ type: null });

  const projectState = useSelector(({ projectState }) => projectState);
  const uiState = useSelector(({ uiState }) => uiState);

  const handleClose = (type) => {
    dispatch(toggleModalOpen({ type: null, payload: {}, open: false }));
  };

  useEffect(() => {
    if (content_type === "course" && id) {
      // fetch course and store it in seperate state.
      dispatch(toggleLoadingProject());

      return Promise.all([
        dispatch(fetchCourseById(id)),
        dispatch(fetchProjectById(id)),
      ])
        .then(([course, project]) => {
          const lectureId = course.payload.sections[0].lectures[0]?.id;

          if (!lectureId) {
            return Promise.resolve({
              status: "noLecture",
              mesage: "No lectures found",
            });
          }

          return Promise.all([
            dispatch(
              fetchLectureById({
                id: lectureId,
                contentType: "lecture",
              })
            ),
            dispatch(
              selectLecture({
                sectionIndex: 0,
                itemId: lectureId,
                itemIndex: 0,
              })
            ),
          ]);
        })
        .finally(() => {
          dispatch(toggleLoadingProject());
          dispatch(
            toggleModalOpen({ type: "tutorial", payload: {}, open: true })
          );
        });
    }
    // fetch article or script content for simple projects
    dispatch(fetchById({ id, contentType: content_type }));
  }, [id, dispatch, content_type]);

  useEffect(() => {
    if (content_type) {
      setSelectedDigest({ type: content_type });
    }
  }, [content_type]);

  const digestsViews = {
    script: (
      <ScriptDigest
        id={id}
        sideBarExpanded={sideBarExpanded}
        project={projectState}
        extensions={tipTapArticleExtension}
      />
    ),
    article: (
      <ArticleDigest
        id={id}
        sideBarExpanded={sideBarExpanded}
        project={projectState}
        extensions={tipTapArticleExtension}
      />
    ),
    course: (
      <DigestCourse
        id={id}
        sideBarExpanded={sideBarExpanded}
        project={projectState}
        isLoading={uiState.loadingProject}
        extensions={tipTapArticleExtension}
      />
    ),
  };

  return (
    <Layout sideMenuOpen={false}>
      <Meta title="Project" />
      <Container className={styles.projectView}>
        <ProjectHeader id={id} />
        {digestsViews[selectedDigest.type] || <div>Select a digest</div>}
      </Container>
      <Attachements.Modal
        open={uiState.modal.type === "attachements" && uiState.modal.open}
        {...uiState.modal.payload}
        handleClose={handleClose}
      />
      <PaywallModal
        handleClose={handleClose}
        open={uiState.modal.type === "paywall" && uiState.modal.open}
      />
      <TutorialModal
        handleClose={handleClose}
        open={uiState.modal.type === "tutorial" && uiState.modal.open}
        // open={true}
      />
    </Layout>
  );
};

// export default ProjectView;
export default requireAuth(ProjectView);
