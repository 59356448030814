import React from "react";
import Meta from "./../components/Meta";
import LegalSection from "./../components/LegalSection";
import { useRouter } from "./../util/router";
import PageLayout from "./../components/PageLayout";

function LegalPage(props) {
  const router = useRouter();

  return (
    <PageLayout>
      <Meta title="Legal" />
      <LegalSection
        bg="white"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        section={router.query.section}
        key={router.query.section}
      />
    </PageLayout>
  );
}

export default LegalPage;
