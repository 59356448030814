import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { List } from "rsuite";

import { useSelector, useDispatch } from "react-redux";

import { reduxState } from "../../../interfaces/reduxState";
import { Section as SectionInterface } from "../../../interfaces/Project";
import { toggleDraggingSection } from "../../../reducers/uiSlice";

import SectionHeader from "./SectionHeader";
import SectionBody from "./SectionBody";

import styles from "./lectureList.module.scss";

interface SectionProps {
  provided: any;
  snapshot: any;
  index: number;
  section: SectionInterface;
  onItemAdd: () => void;
  onItemDelete: (itemIndex: number, lectureId: string) => void;
  onSectionDelete: () => void;
  onSectionNameChange: (newName: string) => void;
  onItemSelect: (lectureIndex: number, lectureId: string) => void;
  onItemNameChange: (
    itemIndex: number,
    lectureId: string,
    newName: string
  ) => void;
}

const Section = ({
  provided,
  snapshot,
  index,
  section,
  onItemAdd,
  onItemDelete,
  onSectionDelete,
  onSectionNameChange,
  onItemNameChange,
  onItemSelect,
}: SectionProps) => {
  const dispatch = useDispatch();
  const [folded] = useState(false);
  const { lectures, title } = section;

  const { id: selectedId } = useSelector(
    ({ uiState }: reduxState) => uiState.selectedLecture
  );

  const { draggingSection } = useSelector(({ uiState }: reduxState) => uiState);

  // selectedLecture
  return (
    <Draggable key={section.id} draggableId={section.id} index={index}>
      {(provided: any, snapshot: any) => (
        <List.Item
          className={styles.section}
          // className={
          //   snapshot.isDraggingOver
          //     ? styles.draggedOverSection
          //     : snapshot.isDragging
          //     ? styles.draggingSection
          //     : styles.section
          // }
          size={"sm"}
          key={section.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          // style={snapshot.isDraggingOver ? { boxShadow: "none" } : {}}
          // style={{ paddingRight: "15px" }}
        >
          <SectionHeader
            folded={draggingSection === section.id}
            title={title}
            dragHandleProps={provided.dragHandleProps}
            progress={section.progress}
            onSectionDelete={onSectionDelete}
            onSectionNameChange={onSectionNameChange}
            onDragStart={(isDragging) => {
              if (isDragging) {
                dispatch(toggleDraggingSection(section.id));
              } else {
                dispatch(toggleDraggingSection(null));
              }
            }}
            onFold={() => {
              // setFolded(!folded);
              // if (draggingSection === section.id) {
              //   return dispatch(toggleDraggingSection(null));
              // } else {
              //   return dispatch(toggleDraggingSection(section.id));
              // }
            }}
          />
          <SectionBody
            index={index}
            lectures={lectures}
            folded={draggingSection === section.id || folded}
            selectedId={selectedId}
            onItemAdd={onItemAdd}
            onItemDelete={onItemDelete}
            onItemNameChange={onItemNameChange}
            onItemSelect={onItemSelect}
          />
        </List.Item>
      )}
    </Draggable>
  );
};

export default Section;
