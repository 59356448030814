import { useEffect, useCallback } from "react";
import { Content, FlexboxGrid } from "rsuite";

import { useState } from "react";
// import {  IconButton, Footer } from "rsuite";

import { useEditor } from "@tiptap/react";
import { useDispatch, useSelector } from "react-redux";

import { updateScript } from "../../../reducers/projectStateSlice";
import { setFilter } from "../../../reducers/uiSlice";
import defaultContent from "../../../tipTap/defaultContent";

// import Scenes from "../Scenes/Scenes";
// import ListIcon from "@rsuite/icons/List";

import { resetProject } from "../../../reducers/projectStateSlice";

import TopActionBar from "../TopActionBar/TopActionBar";
import TextStats from "../TextStats/TextStats";

import ArticleScript from "../Script/Script";
import { debounce } from "../../../util/appUtils";

import styles from "./digest.module.scss";

let setInitScript = false;

const Digest = ({ id, sideBarExpanded, project, extensions }) => {
  const dispatch = useDispatch();
  const { scriptItemFilters } = useSelector((state) => state.uiState);

  const [layout] = useState([2, 20, 2]);

  const editor = useEditor({
    extensions: extensions,
    editable: true,
    onTransaction: (state) => {},
    // reduces the load on the DB, text doesn't have to be saved every second.
    onUpdate: debounce(({ editor }) => {
      dispatch(updateScript(editor.getJSON()));
    }, 450),
  });

  const handleItemCreate = useCallback(
    (contentType) => {
      //TODO: use global dataTypes
      const contentTypeMap = {
        article: "New Paragraph",
        script: "New Scene",
      };
      return editor
        .chain()
        .focus("end")
        .scrollIntoView()
        .insertContent({
          ...defaultContent,
          attrs: { name: contentTypeMap[contentType], contentType },
        })
        .run();
    },
    [editor]
  );

  const handleSnippetInsert = ({ scene, script }) => {
    console.log("selectedSnippet", scene, script);
    return editor
      .chain()
      .focus("end")
      .scrollIntoView()
      .insertContent({
        ...script,
        attrs: { name: scene.name },
      })
      .run();
  };

  useEffect(() => {
    return () => {
      console.log("unmounting");
      // On unmount
      dispatch(resetProject());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    if (
      setInitScript === false &&
      project.activeDiggest?.script?.content.length > 0
    ) {
      setInitScript = true;
      editor.commands.setContent(project.activeDiggest?.script?.content);
    } else {
      // Set default script for nex project
      if (!setInitScript && project.activeDiggest?.contentType) {
        handleItemCreate(project.activeDiggest?.contentType);
      }
    }
  }, [project, handleItemCreate, editor]);

  useEffect(() => {
    return () => {
      // On unmount
      setInitScript = false;
    };
  }, []);

  if (!project.activeDiggest) {
    return null;
  }

  return (
    <>
      <FlexboxGrid style={{ overflow: "auto", height: "100%" }}>
        <FlexboxGrid.Item colspan={layout[0]}></FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={layout[1]}>
          <Content className={styles.scriptContainer}>
            <ArticleScript
              editor={editor}
              onItemCreate={() =>
                handleItemCreate(project.activeDiggest?.contentType)
              }
              onSnippetInsert={handleSnippetInsert}
            />
          </Content>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          colspan={layout[2]}
          style={{
            height: "100%",
            position: "fixed",
            right: "0px",
            paddingBottom: "75px",
            fontSize: "15px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <TopActionBar
            contentType={project.activeDiggest?.contentType}
            filters={scriptItemFilters}
            onFilterChange={(setFilters) => dispatch(setFilter(setFilters))}
            scenes={project.activeDiggest.scenes}
          ></TopActionBar>
          <TextStats
            characterCount={editor?.storage.characterCount}
            withFooter={project.activeDiggest?.contentType === "article"}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default Digest;

const OtherDigest = Digest;

export { OtherDigest };
