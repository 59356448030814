import { IconButton, Dropdown } from "rsuite";

import { Attachment } from "../../../interfaces/Scene";

import { BiLinkAlt } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { MdOutlineEdit } from "react-icons/md";

import styles from "./attachementList.module.scss";

const AttachementList = ({
  attachements,
  onItemDelete,
  onItemUpdate,
  onClose,
}: {
  attachements: Attachment[];
  onItemDelete: (id: string, blockId: string | undefined) => void | null;
  onItemUpdate: (
    id: string,
    title: string,
    url: string,
    blockId: string | undefined
  ) => void | null;
  onClose: () => void;
}) => {
  return (
    <Dropdown.Menu
      onSelect={(select: any) => {
        window.open(select.url, "_blank", "noopener,noreferrer");
      }}
      className={styles.itemList}
    >
      {attachements.map(({ id, type, title, url, blockId }) => {
        return (
          <Dropdown.Item
            icon={
              <BiLinkAlt
                className={styles.attachementIcon}
                style={{ width: "15px", height: "15px" }}
              />
            }
            key={id}
            eventKey={{ url }}
            className={styles.attachementItem}
          >
            <div className={styles.itemContent}>
              <span className={styles.link}>{title}</span>
              <span className={styles.buttons}>
                <IconButton
                  size="xs"
                  icon={<MdOutlineEdit />}
                  className={styles.deleteIcon}
                  appearance="link"
                  circle
                  onClick={(e) => {
                    e.stopPropagation();

                    onClose();
                    onItemUpdate(id, title, url, blockId);
                  }}
                />
                <IconButton
                  size="xs"
                  icon={<IoClose />}
                  className={styles.deleteIcon}
                  appearance="link"
                  circle
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                    onItemDelete(id, blockId);
                  }}
                />
              </span>
            </div>
          </Dropdown.Item>
        );
      })}
    </Dropdown.Menu>
  );
};

export default AttachementList;
