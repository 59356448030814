import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAllSnippets } from "../state/Firebase";

export const fetchAllSnippets = createAsyncThunk(
  "snippets/getAllSnippets",
  async () => await getAllSnippets()
);

export const snippetSlice = createSlice({
  name: "snippets",
  initialState: {
    // value: 0,
  },
  reducers: {
    addSnippet: (state, action) => {
      //
    },
    removeSnippet: (state) => {
      //
    },
    updateSnippet: (state) => {
      //
    },
    getSnippet: (state) => {
      //
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchAllSnippets.fulfilled, (state, action) => {
      // Initial data fetch from Firestore
      action.payload.docs.forEach((project) => {
        const newSnippet = project.data();
        state[newSnippet.scene.blockId] = newSnippet;
      });
    });
  },
});

// Action creators are generated for each case reducer function
export const { addSnippet, removeSnippet, updateSnippet, getSnippet } =
  snippetSlice.actions;

export default snippetSlice.reducer;
