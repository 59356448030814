import { Draggable, Droppable } from "react-beautiful-dnd";
import { List, IconButton } from "rsuite";

import { Lecture } from "../../../interfaces/Project";

import PlusIcon from "@rsuite/icons/Plus";
import WithToolTip from "../WithToolTip/WithToolTip";

import LectureItem from "./LectureItem";

import styles from "./lectureList.module.scss";

interface SectionBodyProps {
  index: number;
  lectures: Lecture[];
  folded: boolean;
  selectedId: string | null;
  onItemAdd: () => void;
  onItemDelete: (itemIndex: number, lectureId: string) => void;
  onItemNameChange: (
    itemIndex: number,
    lectureId: string,
    newName: string
  ) => void;
  onItemSelect: (lectureIndex: number, lectureId: string) => void;
}

export default function SectionBody({
  index,
  lectures,
  folded,
  onItemAdd,
  onItemDelete,
  onItemNameChange,
  onItemSelect,
  selectedId,
}: SectionBodyProps) {
  return (
    <Droppable type="droppableLecture" droppableId={`${index}`}>
      {(provided: any) => (
        <List
          hover
          size="sm"
          {...provided.droppableProps}
          ref={provided.innerRef}
          // TODO: update global state to grey out all of the text related UI elements
          // style={getListStyle(snapshot.isDraggingOver)}
          className={styles.lectureList}
          style={{
            boxShadow: "none",
          }}
        >
          {folded
            ? null
            : lectures.map((item: Lecture, lectureIndex: number) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={lectureIndex}
                >
                  {(provided: any, snapshot: any) => (
                    <LectureItem
                      inFocus={item.id === selectedId}
                      item={item}
                      provided={provided}
                      snapshot={snapshot}
                      onItemDelete={() => {
                        onItemDelete(lectureIndex, item.id);
                      }}
                      onItemNameChange={(newName) =>
                        onItemNameChange(lectureIndex, item.id, newName)
                      }
                      onClick={() => {
                        if (item.id !== selectedId) {
                          onItemSelect(lectureIndex, item.id);
                        }
                      }}
                    />
                  )}
                </Draggable>
              ))}
          {provided.placeholder}
          <AddLectureBtn folded={folded} onItemAdd={() => onItemAdd()} />
          {/* {provided.placeholder} */}
        </List>
      )}
    </Droppable>
  );
}

const AddLectureBtn = ({
  onItemAdd,
  folded,
}: {
  folded: boolean;
  onItemAdd: () => void;
}) => {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5px",
        marginBottom: "3px",
        display: folded ? "none" : "flex",
      }}
    >
      <WithToolTip tooltip="Create New Lecture" trigger="hover">
        <IconButton
          onClick={() => onItemAdd()}
          appearance="subtle"
          circle
          size="xs"
          icon={<PlusIcon />}
        />
      </WithToolTip>
    </div>
  );
};
