import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAllProjects } from "../state/Firebase";

// First, create the thunk
export const fetchProjects = createAsyncThunk(
  "projects/fetchProjects",
  async (userId: string) => await getAllProjects(userId)
);

interface InitState {
  [key: string]: any;
}

const initialState: InitState = {};

export const projectsSlice = createSlice({
  name: "projects",
  initialState: initialState,
  reducers: {
    remove: (projects, { payload }: { payload: string }) => {
      delete projects[payload];
    },
    removeProject: (projects, { payload }) => {
      delete projects[payload];
    },
    add: (projects, { payload }) => {
      projects[payload.id] = { id: payload.id, name: "new project" };
    },
    addDigest: (projects, { payload }) => {
      // INFO: Projects state might just be empty. So only write to DB.
      projects[payload.updatedProject.id] = {
        ...projects[payload.updatedProject.id],
        digests: [...payload.updatedProject.digests],
      };
    },
    addProject: (projects, { payload }) => {
      console.log("addProject", payload);
      projects[payload.id] = {
        ...payload,
      };
    },

    update: (projects, { payload }) => {
      projects[payload.id] = payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      // Initial data fetch from Firestore

      action.payload.docs.forEach((project) => {
        const newProject = project.data();
        state[newProject.id] = newProject;
      });
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  add,
  addProject,

  addDigest,
  remove,
  removeProject,
  update,
} = projectsSlice.actions;

export default projectsSlice.reducer;
