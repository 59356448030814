import React from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router";

import PageLayout from "./../components/PageLayout";

function AuthPage(props) {
  const router = useRouter();

  // const additionalProviders = ["facebook", "twitter"];

  return (
    <PageLayout>
      <Meta title="Auth" />
      <AuthSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        type={router.query.type}
        providers={["google"]}
        afterAuthPath={router.query.next || "/app/projects"}
      />
    </PageLayout>
  );
}

export default AuthPage;
