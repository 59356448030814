import { configureStore } from "@reduxjs/toolkit";

import projectsReducer from "../reducers/projectsSlice";
import uiReducer from "../reducers/uiSlice";
import projectStateReducer from "../reducers/projectStateSlice";
import courseStateReducer from "../reducers/courseStateSlice";
import snippetReducer from "../reducers/snippetSlice";

import { dbNames } from "../DataTypes/dataTypes";
import { NewProject } from "../interfaces/reduxState";

import * as dataManipulator from "../state/interceptors";

// import uniqid from "uniqid";

import {
  addEntry,
  updateEntity,
  updateEntityProperty,
  deleteEntry,
  deleteMultipleEntries,
  // createProject,
  // removeScene,
  updateProjectName,
  updateProject,
  // removeProject,
  addSnippet,
  // addProject,
  // addProjectWithContent,
  removeProjectWithContent,
  updateContentDigest,
  updateProjectDigests,
  addContentDigest,
} from "./Firebase";
import { Lecture, Section } from "../interfaces/Project";

const logger = (store: any) => (next: any) => (action: any) => {
  // console.log("dispatching", action);

  // Switch statement for actions
  switch (action.type) {
    // Updates the project name in the header
    case "projectState/updateName":
      updateProjectName(action.payload);
      return next(action);

    case "projectState/updateOrder":
      updateContentDigest(store.getState().projectState.activeDiggest);
      return next(action);

    case "projectState/updateSceneName":
      updateContentDigest(store.getState().projectState.activeDiggest);
      return next(action);

    case "projectState/updateSceneType":
      updateContentDigest(store.getState().projectState.activeDiggest);
      return next(action);

    case "projectState/updateSceneProgress":
      // save to DB after the progress has been updated in state
      next(action);
      updateContentDigest(store.getState().projectState.activeDiggest);
      return;

    case "projectState/removeSceneById":
      updateContentDigest(store.getState().projectState.activeDiggest);
      return next(action);

    case "projectState/updateScript":
      updateContentDigest({
        ...store.getState().projectState.activeDiggest,
        script: action.payload,
      });
      return next(action);

    case "projectState/updateScenes":
      updateContentDigest({
        ...store.getState().projectState.activeDiggest,
        scenes: action.payload,
      });
      return next(action);

    case "projectState/addAttachment":
      next(action);
      updateContentDigest(store.getState().projectState.activeDiggest);
      return;

    case "projectState/updateAttachment":
      next(action);
      updateContentDigest(store.getState().projectState.activeDiggest);
      return;

    case "projectState/deleteAttachment":
      next(action);
      updateContentDigest(store.getState().projectState.activeDiggest);
      return;

    // Add new metadata as scene
    case "projectState/addScene":
      updateContentDigest(store.getState().projectState.activeDiggest);
      return next(action);

    // case "projects/remove":
    //   removeProject(action.payload);
    //   return next(action);

    case "projects/addDigest":
      const newDigest = dataManipulator.createNewDigest(
        action.payload,
        store.getState().projectState.activeDiggest
      );
      updateProjectDigests(newDigest.updatedProject);
      addContentDigest(newDigest.dbName, newDigest.newDigestContent);
      return next(action);

    case "projects/update":
      updateProject(action.payload);
      return next(action);

    case "projects/addProject":
      const { userId, projectType } = action.payload;

      const { newProject, newContent, dbName }: NewProject =
        dataManipulator.createNewProject({
          userId,
          projectType: projectType.value,
        });

      addEntry({ id: newProject.id, dbName: "Projects", newEntry: newProject });
      // Adds course, script and article to the DB.
      addEntry({ id: newContent.id, dbName: dbName, newEntry: newContent });

      if (action.payload.projectType.value === "course") {
        const newLecture = dataManipulator.createNewLecture(
          newContent.sections[0].lectures[0].id
        );

        addEntry({
          id: newLecture.id,
          dbName: "Lectures",
          newEntry: newLecture,
        });
      }

      return next({
        ...action,
        payload: newProject,
      });

    // TODO: update the case to also work for courses.
    case "projects/removeProject":
      // TODO: Only allow to delete projects that match your userid. (security)

      // TODO: Remove the course
      // TODO: Remove the associated lectures

      removeProjectWithContent({
        projectId: action.payload,
        dbNames,
      });
      return next(action);

    case "courseState/addLecture":
      next(action);

      // Write to the DB after the state has been updated.
      const newLecture = dataManipulator.createNewLecture(
        action.payload.newLecture.id
      );
      addEntry({
        id: newLecture.id,
        dbName: "Lectures",
        newEntry: newLecture,
      });
      updateEntity({
        updatedEntity: store.getState().courseState.course,
        dbName: "Courses",
      });

      return;
    case "courseState/addSection":
      next(action);

      // Write to the DB after the state has been updated.
      addEntry({
        id: action.payload.newSection.lectures[0].id,
        dbName: "Lectures",
        newEntry: dataManipulator.createNewLecture(
          action.payload.newSection.lectures[0].id
        ),
      });
      updateEntity({
        updatedEntity: store.getState().courseState.course,
        dbName: "Courses",
      });

      return;
    case "courseState/deleteLecture":
      // TODO: Remove Lecture entry DB
      // Write to the DB after the state has been updated.
      next(action);

      deleteEntry({ id: action.payload.lectureId, dbName: "Lectures" });

      updateEntity({
        updatedEntity: store.getState().courseState.course,
        dbName: "Courses",
      });
      return;
    case "courseState/deleteSection":
      const lectureIdsToDelete = store
        .getState()
        .courseState.course.sections.find(
          (section: Section) => section.id === action.payload.sectionId
        )
        .lectures.map((lecture: Lecture) => lecture.id);

      deleteMultipleEntries({ dbName: "Lectures", ids: lectureIdsToDelete });

      next(action);
      // Write to the DB after the state has been updated.
      updateEntity({
        updatedEntity: store.getState().courseState.course,
        dbName: "Courses",
      });
      return;

    case "courseState/updateWordCount":
      next(action);
      updateEntity({
        updatedEntity: store.getState().courseState.course,
        dbName: "Courses",
      });
      return;

    case "courseState/updateLectureProgress":
      // Write to the DB after the state has been updated.
      next(action);
      updateEntity({
        updatedEntity: store.getState().courseState.course,
        dbName: "Courses",
      });
      return;

    case "courseState/addSceneUpdate":
      // Write to the DB after the state has been updated.
      next(action);
      updateEntity({
        updatedEntity: store.getState().courseState.course,
        dbName: "Courses",
      });
      return;

    case "courseState/updateSectionTitle":
      // Write to the DB after the state has been updated.
      next(action);
      updateEntity({
        updatedEntity: store.getState().courseState.course,
        dbName: "Courses",
      });
      return;
    case "courseState/updateLectureTitle":
      // Write to the DB after the state has been updated.

      updateEntityProperty({
        dbName: "Lectures",
        id: action.payload.lectureId,
        property: "name",
        value: action.payload.newName,
      });

      next(action);
      updateEntity({
        updatedEntity: store.getState().courseState.course,
        dbName: "Courses",
      });
      return;

    case "courseState/updateSectionPosition":
      // Write to the DB after the state has been updated.
      next(action);
      updateEntity({
        updatedEntity: store.getState().courseState.course,
        dbName: "Courses",
      });
      return;

    case "courseState/updateLecturePosition":
      // Write to the DB after the state has been updated.
      next(action);
      updateEntity({
        updatedEntity: store.getState().courseState.course,
        dbName: "Courses",
      });
      return;

    // Snippets
    case "snippets/addSnippet":
      addSnippet(action.payload);
      return next(action);

    default:
      return next(action);
  }
};

export default configureStore({
  reducer: {
    projects: projectsReducer,
    snippets: snippetReducer,
    // new project state
    projectState: projectStateReducer,
    //
    courseState: courseStateReducer,
    //
    uiState: uiReducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    logger,
  ],
});
