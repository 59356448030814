import { useEffect, useCallback, useState } from "react";
import { IconButton, Content, FlexboxGrid, Placeholder } from "rsuite";

import { useEditor } from "@tiptap/react";
import { useDispatch, useSelector } from "react-redux";

import {
  updateScript,
  resetProject,
} from "../../../../reducers/projectStateSlice";

import { updateWordCount } from "../../../../reducers/courseStateSlice";
import { setFilter, toggleModalOpen } from "../../../../reducers/uiSlice";
import defaultContent from "../../../../tipTap/defaultContent";

import ArticleScript from "../../Script/Script";
import { debounce } from "../../../../util/appUtils";
// import { useAuth } from "../../../../util/auth";
import { maxWordCount } from "../../../../util/prices";

import ListIcon from "@rsuite/icons/List";

import LecturePanel from "../../LecturePanel/LecturePanel";
import DigestSideMenu from "../DigestSideMenu";
import DigestFooter from "../DigestFooter";

import styles from "./courseDigest.module.scss";

let setInitScript = false;

const WithPlaceholder = ({ children, loading }) => {
  if (loading) {
    return (
      <div
        style={{
          margin: "56px 120px",
          // margin: "0 50px",
        }}
      >
        <Placeholder.Graph
          height={200}
          active
          style={{
            marginBottom: "20px",
            borderRadius: "10px",
          }}
        />
        <Placeholder.Graph
          height={200}
          active
          style={{
            marginBottom: "20px",
            borderRadius: "10px",
          }}
        />
        <Placeholder.Graph
          height={200}
          active
          style={{
            marginBottom: "20px",
            borderRadius: "10px",
          }}
        />
      </div>
    );
  }
  return children;
};

const Digest = ({ id, sideBarExpanded, project, isLoading, extensions }) => {
  const [layout, toggleLayout] = useState([6, 16, 2]);

  // const auth = useAuth();

  const hideCoursePanel = () => {
    toggleLayout([layout[0] - 4, layout[1] + 4, layout[2]]);
  };

  const showCoursePanel = () => {
    toggleLayout([layout[0] + 4, layout[1] - 4, layout[2]]);
  };

  const dispatch = useDispatch();
  const { scriptItemFilters, selectedLecture } = useSelector(
    (state) => state.uiState
  );

  const { wordCount } = useSelector(({ courseState }) => courseState.course);

  const editor = useEditor({
    extensions: extensions,
    editable: true,
    onTransaction: (state) => {},
    // reduces the load on the DB, text doesn't have to be saved every second.
    onUpdate: debounce(({ editor }) => {
      dispatch(updateScript(editor.getJSON()));

      // check if the word count exceeds the limit.

      // use user plan to define the word limit

      if (wordCount.words > maxWordCount) {
        // if (wordCount.words > maxWordCounts[auth.user.stripePriceId]) {
        dispatch(toggleModalOpen({ type: "paywall", open: true }));
      }

      // Update the word count to the total number of words in the project
      const { sectionIndex, itemIndex: lectureIndex } = selectedLecture;
      dispatch(
        updateWordCount({
          sectionIndex,
          lectureIndex,
          currentCount: editor.storage.characterCount.words(),
        })
      );
    }, 250),
  });

  const handleItemCreate = useCallback(
    (contentType, index) => {
      //TODO: use global dataTypes

      const contentTypeMap = {
        article: "New Paragraph",
        script: "New Scene",
        lecture: "New Scene",
      };

      return editor
        .chain()
        .focus("end")
        .scrollIntoView()
        .insertContent({
          ...defaultContent,
          attrs: {
            name: contentTypeMap[contentType],
            contentType,
            // TODO: set index
            index: index,
          },
        })
        .run();
    },
    [editor]
  );

  const handleReplaceContent = (script) => {
    if (!editor) return;

    editor.commands.setContent(script);
  };

  const handleSnippetInsert = ({ scene, script }) => {
    // TODO: only allows to insert at the end of the script
    return editor
      .chain()
      .focus("end")
      .scrollIntoView()
      .insertContent({
        ...script,
        attrs: { name: scene.name, index: project.activeDiggest.scenes.length },
      })
      .run();
  };

  const clearEditorContent = () => {
    if (!editor) return;
    editor.commands.clearContent();
  };

  useEffect(() => {
    console.log("mount");

    return () => {
      console.log("unmounting");
      // On unmount
      dispatch(resetProject());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!editor) return;

    if (
      setInitScript === false &&
      project.activeDiggest?.script?.content.length > 0
    ) {
      setInitScript = true;
      // when the content is passed the first time it's set into the editor.
      editor.commands.setContent(project.activeDiggest?.script?.content);
    } else {
      // TODO: Could be redundant
      // Set default script for nex project
      if (!setInitScript && project.activeDiggest?.contentType) {
        console.log("VALIDATE IF NEEDED");
        // handleItemCreate(project.activeDiggest?.contentType);
      }
    }
  }, [project, handleItemCreate, editor]);

  useEffect(() => {
    return () => {
      // On unmount
      setInitScript = false;
    };
  }, []);

  if (!project.activeDiggest) {
    return null;
  }

  return (
    <>
      <FlexboxGrid style={{ overflow: "auto", height: "100%" }}>
        <FlexboxGrid.Item colspan={layout[0]}>
          <IconButton
            onClick={() => showCoursePanel()}
            appearance="primary"
            size="md"
            style={{
              display: layout[0] <= 2 ? "block" : "none",
              margin: "10px",
              marginTop: "58px",
              position: "fixed",
              zIndex: "1",
            }}
            icon={<ListIcon />}
          />
          {/* {course.sections && ( */}
          <LecturePanel
            clearEditorContent={clearEditorContent}
            handleReplaceContent={handleReplaceContent}
            panelWidth={layout[0]}
            toggleLayout={() => hideCoursePanel()}
          />
          {/* )} */}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={layout[1]} style={{ height: "100%" }}>
          <WithPlaceholder loading={isLoading}>
            {!project.activeDiggest.id ? (
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "80%",
                  color: "#999",
                }}
              >
                Select a lecture to start editing
              </span>
            ) : (
              <Content className={styles.scriptContainer}>
                <ArticleScript
                  editor={editor}
                  onItemCreate={() =>
                    handleItemCreate(
                      project.activeDiggest?.contentType,
                      project.activeDiggest.scenes.length
                    )
                  }
                  onSnippetInsert={handleSnippetInsert}
                />
              </Content>
            )}
          </WithPlaceholder>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          colspan={layout[2]}
          style={{
            height: "100%",
            position: "fixed",
            right: "0px",
            paddingBottom: "75px",
            fontSize: "15px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <DigestSideMenu
            contentType={project.activeDiggest?.contentType}
            scriptItemFilters={scriptItemFilters}
            onFilterChange={(setFilters) => dispatch(setFilter(setFilters))}
            scenes={project.activeDiggest.scenes}
            characterCount={editor?.storage.characterCount}
            withFooter={project.activeDiggest?.contentType === "lecture"}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
      {project.activeDiggest.id && (
        <DigestFooter
          sideBarExpanded={sideBarExpanded}
          editor={editor}
          scenes={project.activeDiggest.scenes}
          handleItemCreate={() => {
            handleItemCreate(
              project.activeDiggest?.contentType,
              project.activeDiggest.scenes.length
            );
          }}
          scriptItemFilters={scriptItemFilters}
        />
      )}
    </>
  );
};

export default Digest;
