import TopActionBar from "../TopActionBar/TopActionBar";
import TextStats from "../TextStats/TextStats";

const DigestSideMenu = ({
  contentType,
  scriptItemFilters,
  onFilterChange,
  scenes,
  characterCount,
  withFooter,
}) => {
  return (
    <>
      <TopActionBar
        contentType={contentType}
        filters={scriptItemFilters}
        onFilterChange={(setFilters) => onFilterChange(setFilters)}
        scenes={scenes}
      />
      <TextStats characterCount={characterCount} withFooter={withFooter} />
    </>
  );
};

export default DigestSideMenu;
