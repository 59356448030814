import React, { useEffect } from "react";
import { useRouter } from "../../../util/router.js";
import { useLocation } from "react-router-dom";
import { Container, Sidebar, Sidenav, Navbar, Nav } from "rsuite";

import LogoPlain from "../Logo/LogoPlain";

import AngleLeftIcon from "@rsuite/icons/legacy/AngleLeft";
import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";
// import DashboardIcon from "@rsuite/icons/Dashboard";
import FolderFillIcon from "@rsuite/icons/FolderFill";
import GearIcon from "@rsuite/icons/Gear";
// import CogIcon from "@rsuite/icons/Cog";

import SideBarContext from "../../../context/sidebarContext";

import { useAuth } from "../../../util/auth";

import styles from "./Layout.module.scss";

// const NavToggle = ({ expand, onChange }) => {
//   return (
//     <Navbar appearance="subtle" className="nav-toggle">
//       <Nav pullRight>
//         <Nav.Item onClick={onChange} style={{ width: 56, textAlign: "center" }}>
//           {expand ? <AngleLeftIcon /> : <AngleRightIcon />}
//         </Nav.Item>
//       </Nav>
//     </Navbar>
//   );
// };

const NavToggle = ({ expand, onChange, settingDetails, active }) => {
  const router = useRouter();
  const auth = useAuth();

  return (
    <Navbar appearance="subtle" className="nav-toggle">
      <Nav>
        <Nav.Menu
          noCaret
          active={active}
          placement="topStart"
          trigger="click"
          title={<GearIcon style={{ width: 20, height: 20 }} size="sm" />}
        >
          <Nav.Item
            eventKey="settings"
            onSelect={() => {
              router.push(settingDetails.route);
            }}
          >
            {settingDetails.label}
          </Nav.Item>
          <Nav.Item
            eventKey="signout"
            onSelect={(foo) => {
              auth.signout();
              router.push(settingDetails.logoutRoute);
            }}
          >
            Sign out
          </Nav.Item>
        </Nav.Menu>
      </Nav>

      <Nav pullRight>
        <Nav.Item onClick={onChange} style={{ width: 56, textAlign: "center" }}>
          {expand ? <AngleLeftIcon /> : <AngleRightIcon />}
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};

const Layout = ({ children, sideMenuOpen = true }) => {
  const [expand, setExpand] = React.useState(sideMenuOpen);
  // let params = useParams();
  let { pathname } = useLocation();

  const routes = {
    // "/app/dashboard": {
    //   icon: <DashboardIcon />,
    //   label: "Dashboard",
    // },
    "/app/projects": {
      icon: <FolderFillIcon />,
      label: "Projects",
    },
  };

  const settingDetails = {
    route: "/app/settings/general",
    logoutRoute: "/auth/signout",
    label: "Settings",
  };

  useEffect(() => {
    setExpand(sideMenuOpen);
  }, [sideMenuOpen]);

  useEffect(() => {
    document.documentElement.classList.toggle("overflow-hidden", true);
    return () => {
      document.documentElement.classList.toggle("overflow-hidden", false);
    };
  }, []);

  const router = useRouter();
  return (
    <div className={styles.layout}>
      <Container>
        <Sidebar
          className={styles.sidebarContainer}
          width={expand ? 180 : 56}
          collapsible
        >
          <Sidenav.Header onClick={() => router.push("/")}>
            <div className={styles.headerStyles}>
              <LogoPlain width={expand ? "26" : "30"} />
              {expand && <span className={styles.brandName}>CourseCrafts</span>}
            </div>
          </Sidenav.Header>
          <Sidenav
            expanded={expand}
            defaultOpenKeys={["3"]}
            appearance="subtle"
            style={{ flexGrow: 1 }}
          >
            <Sidenav.Body
              style={{ flexGrow: 1, textDecoration: "none" }}
              className={styles.body}
            >
              <Nav>
                {Object.keys(routes).map((route, index) => {
                  return (
                    <Nav.Item
                      key={index}
                      eventKey={route}
                      active={pathname === route}
                      onSelect={() => router.push(route)}
                      icon={routes[route].icon}
                    >
                      {routes[route].label}
                    </Nav.Item>
                  );
                })}
              </Nav>
            </Sidenav.Body>
          </Sidenav>
          <NavToggle
            expand={expand}
            onChange={() => setExpand(!expand)}
            settingDetails={settingDetails}
            active={pathname.includes(settingDetails.route)}
          />
        </Sidebar>
        <SideBarContext.Provider value={expand}>
          {children}
        </SideBarContext.Provider>
      </Container>
    </div>
  );
};

export default Layout;
