import { Modal, Button } from "rsuite";

import styles from "./tutorialModal.module.scss";
import { useEffect } from "react";

const YoutubeEmbed = ({ videoId }: { videoId: string }) => {
  return (
    <iframe
      width="640"
      height="390"
      src={`https://www.youtube.com/embed/${"LPWakRFhMas"}`}
      title="YouTube video player"
      // frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      // allowfullscreen
    ></iframe>
  );
};

export default function TutorialModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: (type: string) => void;
}) {
  useEffect(() => {}, [open]);

  return (
    <Modal
      backdrop
      keyboard={false}
      open={open}
      onClose={() => {
        handleClose("tutorial");
      }}
      // style={{ width: 1000 }}
      size={"lg"}
    >
      <Modal.Header>
        <Modal.Title as={"h2"} className={styles.title}>
          Welcome to CourseCrafts.ai: Unleash Your Course Creation Potential!
        </Modal.Title>
        <span className={styles.modalSubtitle}>
          {/* Attach documents and assets to the script. */}
        </span>
      </Modal.Header>

      <Modal.Body className={styles.body}>
        <div className={styles.text}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            {open && <YoutubeEmbed videoId="LPWakRFhMas" />}
          </div>

          <p>
            To kickstart your course creation adventure, I invite you to take a
            guided tour through the interface. This video will provide you with
            invaluable insights into how CourseCrafts.ai streamlines your
            workflow and equips you with powerful tools to curate exceptional
            learning experiences for your students.
          </p>

          {/* 

          <p style={{ margin: "10px 0" }}>Inside the video, you'll discover:</p>
          <ul>
            <li style={{ padding: "5px 0" }}>
              🎯 <b>Intuitive Content Planning:</b> Learn how to effectively
              organize your course content, create outlines, and plan your
              lessons with ease.
            </li>
            <li style={{ padding: "5px 0" }}>
              ✍️ <b>Asset Management:</b> Managing assets becomes a breeze with
              CourseCrafts.ai. Easily access and update code snippets, images,
              animations, and screenshare videos. No more lost or outdated
              assets!
            </li>
            <li style={{ padding: "5px 0" }}>
              📊 <b>Performance Tracking & Analytics:</b> Understand how our
              data-driven insights like length estimation and progress tracking
              help you optimize your course content for maximum impact.
            </li>
            <li style={{ padding: "5px 0" }}>
              💬 <b>Supportive Community:</b> Discover a thriving community of
              course creators, where you can exchange ideas, seek advice, and
              find inspiration. (Coming soon!)
            </li>
          </ul> */}

          {/* <p>
            My mission at CourseCrafts.ai is to empower educators like you to
            make a lasting impact on the lives of learners worldwide. I am
            committed to your success, and the platform is designed with your
            needs in mind, so you can focus on what you do best: creating
            exceptional courses that inspire, educate, and transform.
          </p> */}
          {/* <p>
            So, without further ado, let's dive into the world of
            CourseCrafts.ai and unlock your course creation potential.
          </p> */}
          <br />
          <p>Happy course creating!</p>

          <p>Fabian</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            handleClose("tutorial");
          }}
          appearance="primary"
        >
          Let's get started!
        </Button>
        <Button
          onClick={() => {
            handleClose("tutorial");
          }}
          appearance="subtle"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
