import { Whisper, Dropdown, Progress, Button, Popover } from "rsuite";

import { progressTypeMap } from "../../../../DataTypes/dataTypes";

import { AiOutlineCheckCircle, AiOutlineClockCircle } from "react-icons/ai";

import styles from "../ScriptItem.module.scss";

const ProgressMenu = ({ progress, contentType, handleMenuClick }) => {
  return (
    <Dropdown.Menu
      title="Progress"
      onSelect={(item) => {
        handleMenuClick(item);
      }}
    >
      {Object.entries(progressTypeMap[contentType]).map(
        ([stage, { value, label }]) => {
          const StatusIcon = progress[stage] ? (
            <AiOutlineCheckCircle
              className={styles.progressIcon}
              style={{ color: "#2ac48d" }}
            />
          ) : (
            <AiOutlineClockCircle
              className={styles.pendingIcon}
              style={{ color: "#ffc85a" }}
            />
          );

          return (
            <Dropdown.Item
              icon={StatusIcon}
              key={stage}
              eventKey={{ stage }}
              className={styles.itemIcon}
            >
              {"  "}
              <span className={styles.sceneTypesLabel}>{label}</span>
            </Dropdown.Item>
          );
        }
      )}
    </Dropdown.Menu>
  );
};

const ProgressCircle = ({ contentType, progressObj, handleMenuClick }) => {
  let percent = 0;

  if (progressObj) {
    const progressCount = Object.values(progressObj).filter((item) => item);

    percent = (progressCount.length / Object.values(progressObj).length) * 100;
  }

  const status = percent === 100 ? "success" : null;
  // const color = percent === 100 ? "#52c41a" : "#3385ff";

  const getColor = () => {
    const colorRange = {
      green: "#52c41a",
      blue: "#3385ff",
      red: "#ED7A7A",
      orange: "#ffc85a",
    };

    if (percent === 100) {
      return colorRange.green;
    } else if (percent < 25) {
      return colorRange.red;
    } else if (percent >= 25) {
      return colorRange.orange;
    } else {
      return colorRange.blue;
    }
  };

  return (
    <div style={{ width: 20, marginRight: "10px" }}>
      <Whisper
        placement="auto"
        trigger="click"
        speaker={
          <Popover className={styles.popover}>
            <ProgressMenu
              contentType={contentType}
              progress={progressObj}
              handleMenuClick={handleMenuClick}
            />
          </Popover>
        }
      >
        <Button appearance="subtle" style={{ padding: "5px" }}>
          <Progress.Circle
            percent={percent}
            strokeColor={getColor()}
            status={status}
            showInfo={false}
            strokeWidth={10}
            style={{ width: "20px" }}
          />
        </Button>
      </Whisper>
    </div>
  );
};

export default ProgressCircle;
