import React from "react";
import Meta from "./../components/Meta";
import ContactSection from "./../components/ContactSection";
// import HeroSection2 from "./../components/HeroSection2";

import PageLayout from "./../components/PageLayout";

function BetaSignup(props) {
  return (
    <PageLayout>
      <Meta title="beta sign-up" />
      {/* <HeroSection2
        bg="primary"
        textColor="white"
        size="lg"
        bgImage="./pattern.png"
        bgImageOpacity={0.2}
        title="The mission"
        subtitle="Empower you to deliver more engaging udemy courses in a fraction of the time."
      /> */}
      <ContactSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Sign-up for Beta Access"
        subtitle=""
        buttonText="sign-up now!"
        buttonColor="primary"
        showNameField={true}
      />
    </PageLayout>
  );
}

export default BetaSignup;
