import { useState, useEffect, useRef } from "react";
import { Whisper, Popover, IconButton, Badge } from "rsuite";
import { useDispatch } from "react-redux";

import AttachementList from "../AttachementList/AttachementList";

import { toggleModalOpen } from "../../../reducers/uiSlice";
import {
  // updateAttachment,
  deleteAttachment,
} from "../../../reducers/projectStateSlice";

import AttachmentIcon from "@rsuite/icons/Attachment";

// import styles from "./attachementBar.module.scss";

const AttachementBar = ({ scenes }) => {
  const dispatch = useDispatch();
  const triggerRef = useRef();
  const close = () => triggerRef.current.close();

  const [attachments, setAttachments] = useState([]);

  const onItemDelete = (id, blockId) => {
    dispatch(deleteAttachment({ sceneId: blockId, attachementId: id }));
  };

  const onItemUpdate = (id, title, url, blockId) => {
    //TODO: Values cant be updated in the Modal.

    // dispatch(deleteAttachment({ sceneId, attachementId: id }));
    dispatch(
      toggleModalOpen({
        open: true,
        payload: {
          sceneId: blockId,
          type: "edit",
          attachementId: id,
          defaultTitle: title,
          defaultUrl: url,
        },
      })
    );
  };

  useEffect(() => {
    //TODO: move to TopAction Bar as progress tracker does the same thing
    if (scenes?.length > 0) {
      setAttachments(
        scenes.reduce((acc, { attachments, blockId, ...rest }) => {
          // aggregate attachments
          if (attachments.length === 0) return acc;

          return acc.concat(
            attachments.map((attachment) => ({ ...attachment, blockId }))
          );
        }, [])
      );
    }
  }, [scenes]);

  const renderBadge = (attachments) => {
    if (attachments > 0) {
      return (
        <Badge color="blue" content={attachments}>
          <IconButton
            // appearance={attachments > 0 ? "ghost" : "subtle"}
            appearance={"subtle"}
            icon={<AttachmentIcon style={{ fontSize: "1.5em" }} />}
          />
        </Badge>
      );
    } else {
      return (
        <IconButton
          // appearance={attachments > 0 ? "ghost" : "subtle"}
          appearance={"subtle"}
          disabled
          icon={<AttachmentIcon style={{ fontSize: "1.5em" }} />}
        />
      );
    }
  };

  return (
    <div>
      <Whisper
        placement="auto"
        trigger="click"
        ref={triggerRef}
        speaker={
          <Popover title={"Attachments"}>
            <AttachementList
              attachements={attachments}
              onItemDelete={onItemDelete}
              onItemUpdate={onItemUpdate}
              onClose={close}
            />
          </Popover>
        }
      >
        {renderBadge(attachments.length)}
        {/* <IconButton
          // appearance={attachments > 0 ? "ghost" : "subtle"}
          appearance={"subtle"}
          icon={<AttachmentIcon style={{ fontSize: "1.5em" }} />}
        /> */}
      </Whisper>
    </div>
  );
};

export default AttachementBar;
