import { reverseTypeIconMap } from "../../../DataTypes/dataTypes";

import styles from "./snippetItem.module.scss";

const TitleIcon = ({ type }) => {
  if (!type) return;

  return (
    <span className={styles.titleIcon}>{type && reverseTypeIconMap[type]}</span>
  );
};

export default TitleIcon;
